import { useEffect, useState } from "react";
import { ISkillBase } from "../../data-model";
import { useDelayDebounce } from "../../common/hooks/debounce";
import ConfirmationModal from "../../common/confirmation-dialog";
import { deleteSkillBase, getAllSkills, saveNewSkillBase, syncSkills, updateSkillBase } from "../_services/pcr-rates-svc";
import { toast } from "react-toastify";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import SkillBaseCreate from "./skill-base-create";
import { initialDefaultSkillBase } from "../_services/initial-default-data";

const SkillBaseSummary: React.FC<{}> = () => {
    const [showAddSkillModal, setShowAddSkillModal] = useState(false);
    const [allSkillBases, setAllSkillBases] = useState<ISkillBase[]>();
    const [filteredSkills, setFilteredSkills] = useState<ISkillBase[]>();
    const [fieldFilter, setFieldFilter] = useState<string>('');
    const [loading, setLoading] = useState(true);

    const [currentSkillBase, setCurrentSkillBase] = useState<ISkillBase | null>();
    const [recordToDeleteMessage, setRecordToDeleteMessage] = useState<string>('');
    const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false);
    const delayDebouncedValue = useDelayDebounce(100)

    useEffect(() => {
        if (delayDebouncedValue) {
            fetchAllRoles();
        }
    }, [delayDebouncedValue]);

    useEffect(() => {
        if (fieldFilter?.length > 0) {
            const filteredData = allSkillBases?.filter((item) =>
                item.Skill?.toLowerCase().includes(fieldFilter?.toLowerCase())
                || item.SkillCategory?.toLowerCase().includes(fieldFilter?.toLowerCase())
            );
            setFilteredSkills(filteredData);
        } else {
            setFilteredSkills(allSkillBases);
        }
    }, [fieldFilter]);

    const fetchAllRoles = async () => {
        setLoading(true);
        const data = await getAllSkills();
        if (data) {
            setAllSkillBases(data);
            setFilteredSkills(data)
        } else {
            setAllSkillBases([]);
        }
        setLoading(false);
    };

    const processSkillsSync = async () => {
        setLoading(true);
        let [isSuccess, message] = await syncSkills();
        if (isSuccess) {
            toast.success(`Successfully sync: ${message} `, { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            await fetchAllRoles()
        } else {
            toast.error(`Error occurred: ${message}`, { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoading(false);
    };

    const toggleModal = () => {
        setShowAddSkillModal(!showAddSkillModal);
    };

    const editRole = (skillObj: ISkillBase) => {
        setShowAddSkillModal(!showAddSkillModal);

        setCurrentSkillBase({
            ...skillObj
        });
    };

    const saveRole = async (skillObj: ISkillBase) => {
        let [isSuccess, message] = [false, ''];
        if (skillObj.SkillBaseId != null) {
            [isSuccess, message] = await updateSkillBase(skillObj);
        }
        else {
            [isSuccess, message] = await saveNewSkillBase(skillObj);
        }

        if (isSuccess) {
            setCurrentSkillBase(undefined)
            toast.success("Saved New Role Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            await fetchAllRoles();
        }
        else {
            toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoading(false);
        return [isSuccess, message]
    };

    const handleDeleteRole = (skill: ISkillBase) => {
        setShowRoleDeleteModal(true);
        setCurrentSkillBase(skill);
        setRecordToDeleteMessage(`Are you sure to delete role: '${skill.Skill}'`);
    };

    const confirmDelete = async () => {
        if (currentSkillBase != undefined && currentSkillBase.SkillBaseId != null) {
            let [isSuccess, message] = await deleteSkillBase(currentSkillBase?.SkillBaseId);

            if (isSuccess) {
                toast.success("Role Deleted Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchAllRoles();
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        }
        setCurrentSkillBase(null);
        setShowRoleDeleteModal(false);
    };

    const cancelDelete = () => {
        setCurrentSkillBase(null);
        setShowRoleDeleteModal(false);
    };

    return (
        <div>
            <div>
                <ConfirmationModal
                    show={showRoleDeleteModal}
                    title="Confirm Deletion of Role"
                    message={recordToDeleteMessage}
                    onClose={() => setShowRoleDeleteModal(false)}
                    onCancel={cancelDelete}
                    onConfirm={confirmDelete}
                />
            </div>
            <div>
                {showAddSkillModal && (
                    <SkillBaseCreate show={showAddSkillModal} onClose={toggleModal} onSave={saveRole} initialSkills={currentSkillBase ?? initialDefaultSkillBase} />
                )}
            </div>
            <center><h2>All Skill(s)</h2></center>

            <div className={loading ? 'card-body table-fade' : 'card-body'}>
                <div className="table-container" style={{ minHeight: '850px', maxHeight: '850px', border: '1px solid #ccc' }}>
                    {loading ? (<LoadingIcon />) : ''}
                    <div>
                        <div className="d-flex justify-content-between">
                            <div>
                                {<a style={{ cursor: "pointer", marginLeft: '10px', color: 'blue' }} onClick={() => processSkillsSync()} className="w-100">
                                    <i className="fas fa-sync"></i> Import Skills from Skills-Base
                                </a>
                                }
                                <a onClick={() => setShowAddSkillModal(true)} className="w-100 p-5" style={{ cursor: "pointer" }}>
                                    <i className="fas fa-plus"></i> Add New Skill
                                </a>
                            </div>

                            <p className="pr-3">Display: <strong>{filteredSkills?.length} </strong> Skill(s) out of Total:<strong> {allSkillBases?.length} </strong>  Skills</p>
                        </div>
                        <Table striped bordered hover>
                            <thead style={{ position: 'sticky', top: '0' }}>
                                <tr>
                                    <th className="w-25">
                                        <div className="d-flex justify-content-between">
                                            <p className="mt-0">Skill Name</p>

                                            <Form.Control
                                                type="text"
                                                className="w-75 h-25"
                                                name="SkillName"
                                                placeholder='Filter Skill'
                                                value={fieldFilter}
                                                onChange={(e) => setFieldFilter(e.target.value)}
                                            />
                                        </div>

                                    </th>
                                    <th style={{ width: '15%' }}>Skill Category</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSkills?.map((skill) => (
                                    <tr key={skill.SkillBaseId}>
                                        <td>
                                            <Link title={skill.Description ?? ''} to={`/skill-details/${skill.SkillBaseId}`}>
                                                {skill.Skill}
                                            </Link>
                                        </td>
                                        <td>{skill.SkillCategory}</td>
                                        <td>{skill.Description}</td>
                                        <td>{skill.IsActive ? "Active" : "In-Active"}</td>
                                        <td style={{ width: '55px', border: '1px solid #ccc' }}>
                                            <a onClick={() => editRole(skill)} className="">
                                                <i className="fas fa-edit"></i>
                                            </a>
                                            <a style={{ marginLeft: '5px', color: 'red' }} onClick={() => handleDeleteRole(skill)} className="">
                                                <i className="fas fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center', paddingTop: '10px', cursor: 'pointer' }}>
                <a onClick={() => setShowAddSkillModal(true)} className="w-100" style={{ cursor: "pointer" }}>
                    <i className="fas fa-plus"></i> Add New Skill
                </a>
            </div>
        </div>
    )
};

export default SkillBaseSummary;