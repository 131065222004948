import React, { useState, useEffect } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { HubspotCustomer, IEmployee, ILandingDashboard, IProjectStatus, IProjectSummary } from '../../data-model'; // Import your interface
import { getAllProjectStatus, getAllProjectSummary, getLandingProjectSummary } from '../_services/dashboard-svc';
import { Link } from 'react-router-dom';
import './project-summary-grid.scss'
import LoadingIcon from '../../common/loading-icon/loading-icon';
import { toast } from 'react-toastify';
import { useProjectDataContext } from '../../data-context/project-data-context';
import { defaultProjectEffortSummary, initialDefaultStatus } from '../_services/initial-default-data';
import { calculatePercent, formatDateTimeAsString, formatToUSCurrency, isUserRoleReadOnly } from '../../utilities/helper-function';
import { useDelayDebounce } from '../../common/hooks/debounce';
import AutocompleteSearch from '../../common/auto-complete/auto-complete-search';
import { getAllCWMemberData, getAllMatchedHubspotCustomer, getAllMatchedHubspotDeal } from '../_services/master-data-svc';
import { APIRequestFilter, APIRequestParam } from '../_services/service-request-model/api-request-param';
import GridPagination from '../../common/grid-component/grid-pagination-control';
import { getCurrentUserIsApprover } from '../_services/approval-svc';

const ProjectSummaryGrid: React.FC<{}> = ({ }) => {
  const { setCurrentProjectIdGblCtx, setAllProjectRoleResoursesGblCtx,
    projectEffortSummaryGblCtx, setProjectEffortSummaryGblCtx, userAccessInfoGblCtx } = useProjectDataContext();

  const delayDebouncedValue = useDelayDebounce(100)
  const [allProjects, setAllProjects] = useState<IProjectSummary[]>([]);
  const [landingDashboard, setLandingDashboard] = useState<ILandingDashboard>();
  const [selectedFilterDealStatus, setSelectedFilterDealStatus] = useState<IProjectStatus>(initialDefaultStatus);
  const [allStatus, setAllStatus] = useState<IProjectStatus[]>([]);

  const [loading, setLoading] = useState(true);
  const [filterProjectTitle, setFilterProjectTitle] = useState<string>('');
  const [filterClientName, setFilterClientName] = useState<string>('');
  const [filterClientId, setFilterClientId] = useState<string>('');
  const [filterHubspotDealName, setFilterHubspotDealName] = useState<string>('');
  const [filterHubspotDealId, setFilterHubspotDealId] = useState<string>('');
  const [filterPreparerName, setFilterPreparerName] = useState<string>('');
  const [myFilterPlan, setFilterMyplan] = useState<boolean>(false);
  const [asFilterApprover, setFilterAsApprover] = useState<boolean>(false);
  const [currentUserIsApprover, setCurrentUserIsApprover] = useState<boolean>(false);
  const [readOnlyUI, setReadOnlyUI] = useState<boolean>(true);

  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, settoTalRecords] = useState<number>(0);

  useEffect(() => {
    setCurrentProjectIdGblCtx(undefined);
    setAllProjectRoleResoursesGblCtx([]);
    setProjectEffortSummaryGblCtx(defaultProjectEffortSummary);
    if (delayDebouncedValue > 0) {
      fetchMyProjectDashboard();
      fetchAllProjectStatus();
      fetchUserIsApprover().then((res) => {
        if (res == true) {
          fetchAllProjects(createAPIRequest(currentPage, itemsPerPage, res, false));
        } else {
          fetchAllProjects(createAPIRequest(currentPage, itemsPerPage, res, true));
        }
      }
      );
    }
    setReadOnlyUI(isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles))
  }, [delayDebouncedValue]);

  const fetchAllProjectStatus = async (): Promise<IProjectStatus[]> => {
    try {
      let data = await getAllProjectStatus();
      if (data) {
        setAllStatus(data);
        return data;
      }
    } catch (error) {
      console.error('Error fetching status:', error);
    }
    return [];
  };

  const fetchUserIsApprover = async (): Promise<boolean> => {
    try {
      let data = await getCurrentUserIsApprover();
      if (data) {
        setCurrentUserIsApprover(data);
        setFilterAsApprover(data)
        setFilterMyplan(!data)
      } else {
        setFilterAsApprover(false)
        setFilterMyplan(true)
      }
      return data;
    } catch (error) {
      console.error('Error fetching approver:', error);
    }
    return false;
  };

  const fetchAllProjects = async (apiRequestParam: APIRequestParam) => {
    try {
      setLoading(true);
      let [totalRecords, data] = await getAllProjectSummary(apiRequestParam);
      if (data && totalRecords > 0) {
        setTotalPages(Math.ceil(+(totalRecords / itemsPerPage)));
        setAllProjects(data);
        settoTalRecords(totalRecords);
      } else {
        setTotalPages(0);
        setAllProjects([]);
        settoTalRecords(0);
      }
    } catch (e) {

      toast.error("Unable to connect server", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
    }
    setLoading(false);
  };

  const fetchMyProjectDashboard = async () => {
    try {
      setLoading(true);
      let data = await getLandingProjectSummary();
      if (data) {
        setLandingDashboard(data);
      }
    } catch (e) {
      toast.error("Error occurred. Unable to connect API server", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
    }
    setLoading(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "MyPlan")
      setFilterMyplan(!myFilterPlan)
    if (name === "AsApprover")
      setFilterAsApprover(!asFilterApprover)
    if (name === "ProjectTitle")
      setFilterProjectTitle(value)
  }

  const getFilterObj = (field: string, value: string, text: string) => {
    return {
      Field: field,
      Operator: 'contains',
      Value: value,
      Text: text,
    }
  }

  const createAPIRequest = (pageNumber: number, pageSize: number, isApprover: boolean, myPlan: boolean): APIRequestParam => {
    let filters: APIRequestFilter[] = [];
    if (filterProjectTitle) {
      filters.push(getFilterObj('Title', filterProjectTitle, filterProjectTitle));
    }
    if (myFilterPlan || myPlan) {
      if (myFilterPlan) {
        filters.push(getFilterObj('MyPlan', myFilterPlan ? "1" : "0", myFilterPlan ? "Yes" : "No"));
      } else {
        filters.push(getFilterObj('MyPlan', myPlan ? "1" : "0", myPlan ? "Yes" : "No"));
      }
    }
    if (filterClientName) {
      filters.push(getFilterObj('ClientName', filterClientName, filterClientName));
      filters.push(getFilterObj('ClientId', filterClientId, filterClientId));
    }
    if (filterHubspotDealName) {
      filters.push(getFilterObj('DealName', filterHubspotDealName, filterHubspotDealName));
      filters.push(getFilterObj('DealId', filterHubspotDealId, filterHubspotDealId));
    }
    if (filterPreparerName) {
      filters.push(getFilterObj('Preparer', filterPreparerName, filterPreparerName));
    }
    if (asFilterApprover || isApprover) {
      if (asFilterApprover) {
        filters.push(getFilterObj('AsApprover', asFilterApprover ? "1" : "0", asFilterApprover ? "Yes" : "No"));
      } else {
        filters.push(getFilterObj('AsApprover', isApprover ? "1" : "0", isApprover ? "Yes" : "No"));

      }
    }

    if (selectedFilterDealStatus && selectedFilterDealStatus.StatusId > 0) {
      filters.push(getFilterObj('dealstatus', selectedFilterDealStatus.StatusId.toString(), selectedFilterDealStatus.Status ?? ''));
    }

    const requestParams: APIRequestParam = {
      page: pageNumber,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
      pageSize: pageSize,
      wantTotalCount: true,
      filter: {
        logic: 'AND',
        filters: filters,
      },
      sort: [
        {
          field: 'CreatedDate',
          dir: 'asc',
        },
      ],
    };
    return requestParams;
  }

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    let requestParams: APIRequestParam = createAPIRequest(page, itemsPerPage, asFilterApprover, myFilterPlan);
    await fetchAllProjects(requestParams);
  };

  const handleFilterReset = () => {
    setCurrentPage(1);
    setFilterMyplan(false)
    setFilterAsApprover(false)
    setAllProjects([])
    setFilterProjectTitle('')
    setFilterClientName('')
    setFilterHubspotDealName('')
    setFilterPreparerName('')
    setSelectedFilterDealStatus(initialDefaultStatus)
  };

  const filterProjectPlan = async () => {
    let page = 1;
    setCurrentPage(page);
    let requestParams: APIRequestParam = createAPIRequest(page, itemsPerPage, asFilterApprover, myFilterPlan);
    await fetchAllProjects(requestParams);
  }

  const handlePreparerSelected = (item: IEmployee | undefined, selectedText: string) => {
    setFilterPreparerName((item != undefined ? item.fullName : selectedText) ?? '');
  };

  const handleClientNameSelected = (item: HubspotCustomer | undefined, selectedText: string) => {
    if (item) {
      setFilterClientName(item.name ?? '');
      if (item.value) {
        setFilterClientId(item.value);
      }
    } else {
      setFilterClientName(selectedText);
      setFilterClientId('')
    }
  };

  const handleHubspotDealSelected = (item: HubspotCustomer | undefined, selectedText: string) => {
    if (item) {
      setFilterHubspotDealName(item.name ?? '');
      if (item.value)
        setFilterHubspotDealId(item.value);
    } else {
      setFilterHubspotDealName(selectedText);
      setFilterHubspotDealId('');
    }
  };

  const handleProjectStatusChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    let status = allStatus.filter(f => f.StatusId == +value)[0];
    setSelectedFilterDealStatus(status);
  }

  return (
    <div>
      <div className="row">
        {/*  <!-- Earnings (Monthly) Card Example --> */}
        <div className="col-xl-2 col-md-2 mb-1">
          <div className="card border-left-primary shadow h-60 py-1">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Your Project</div>
                  <div className="h6 mb-0 font-weight-bold text-gray-800">{landingDashboard?.TotalProject}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-solid fa-list fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-2 col-md-2 mb-1">
          <div className="card border-left-info shadow h-60 py-1">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    In-Progress Deal Cost</div>
                  <div className="h6 mb-0 font-weight-bold text-gray-800">{formatToUSCurrency(landingDashboard?.InProgressDealCost ?? null)}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  <!-- Earnings (Monthly) Card Example --> */}
        <div className="col-xl-2 col-md-2 mb-1">
          <div className="card border-left-success shadow h-60 py-1">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Total Deal Won</div>
                  <div className="h6 mb-0 font-weight-bold text-gray-800">{landingDashboard?.TotalDealWon}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-user fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-2 col-md-2 mb-1">
          <div className="card border-left-danger shadow h-60 py-1">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-error text-uppercase mb-1">
                    Total Deal Lost</div>
                  <div className="h6 mb-0 font-weight-bold text-gray-800">{landingDashboard?.TotalDealLost}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-user fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-2 col-md-2 mb-1">
          <div className="card border-left-secondary shadow h-60 py-1">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Total Submitted Deal</div>
                  <div className="h6 mb-0 font-weight-bold text-gray-800">{landingDashboard?.TotalProgress}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-solid fa-list fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  <!-- Earnings (Monthly) Card Example --> */}
        <div className="col-xl-2 col-md-2 mb-1">
          <div className="card border-left-info shadow h-60 py-1">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Progress
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <div className="ml-3 h6 mb-0 mr-3 font-weight-bold text-gray-800">{calculatePercent(((landingDashboard?.TotalDealWon ?? 0) + (landingDashboard?.TotalDealLost ?? 0)), landingDashboard?.TotalProject ?? 0)?.toFixed(0)}%</div>
                    </div>
                    <div className="col">
                      <div className="progress progress-sm mr-2">
                        <div className="progress-bar bg-info a1" role="progressbar" style={{ width: `${calculatePercent(((landingDashboard?.TotalDealWon ?? 0) + (landingDashboard?.TotalDealLost ?? 0)), landingDashboard?.TotalProject ?? 0)?.toFixed(0)}%` }}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ minHeight: '90px', maxHeight: '120px', border: '1px solid #ccc' }}>
        <div className="col-md-2">
          <Form.Group controlId="formProject">
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="ProjectTitle"
              type="text"
              placeholder='Type Title...'
              value={filterProjectTitle}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
        <div className="col-md-2">
          <Form.Group controlId="formClientName">
            <Form.Label>Client Name</Form.Label>
            <AutocompleteSearch
              OnItemSelected={handleClientNameSelected}
              SelectedInitialItem={filterClientName}
              PlaceHolder='Type Customer Name..'
              APICallback={(searchText) => getAllMatchedHubspotCustomer(searchText)}
              AllowFreeText={true}
              ShowVaidationError={false}
              DisplayProperty={(item) => item.name ?? 'name'}
            />
          </Form.Group>
        </div>
        <div className="col-md-2">
          <Form.Group controlId="formDealName">
            <Form.Label>Deal Name</Form.Label>
            <AutocompleteSearch
              OnItemSelected={handleHubspotDealSelected}
              SelectedInitialItem={filterHubspotDealName}
              PlaceHolder='Type Deal..'
              APICallback={(searchText) => getAllMatchedHubspotDeal(searchText)}
              AllowFreeText={true}
              ShowVaidationError={false}
              DisplayProperty={(item) => item.name ?? 'name'}
            />
          </Form.Group>
        </div>
        <div className="col-md-2">
          <Form.Group controlId="formDealStatus">
            <Form.Label>Status</Form.Label>
            <div>
              <select style={{ borderColor: 'white', minHeight: '35px', borderRadius: '5px' }} id="status" name="status"
                value={selectedFilterDealStatus?.StatusId}
                onChange={handleProjectStatusChange}>
                <option value="-1">-Select-</option>
                {allStatus.map((status) => (
                  <option style={{ fontWeight: 'bold', color: status.StatusId == 50 ? 'red' : (status.StatusId == 299 || status.StatusId == 300) ? 'green' : '' }}
                    key={status.StatusId} value={status.StatusId}>
                    {status.Description}
                  </option>
                ))}
              </select>
            </div>
          </Form.Group>
        </div>
        <div className="col-md-2">
          <Form.Group controlId="formProject">
            <Form.Label>Preparer</Form.Label>
            <AutocompleteSearch
              OnItemSelected={handlePreparerSelected}
              SelectedInitialItem={filterPreparerName}
              PlaceHolder='Type Preparer..'
              APICallback={(searchText) => getAllCWMemberData(searchText)}
              AllowFreeText={true}
              ShowVaidationError={false}
              DisplayProperty={(item) => item.fullName ?? 'fullName'}
            />
          </Form.Group>
        </div>
        <div className="col-md-1">
          <Form.Label>Who?</Form.Label>
          <Form.Group controlId="formCheckboxMyPlan">
            <Form.Check
              //style={{ marginTop: '1px' }}
              name="MyPlan"
              type="checkbox"
              label="My Plan"
              checked={myFilterPlan}
              onChange={handleInputChange}
            />
          </Form.Group>
          {currentUserIsApprover && <Form.Group controlId="formCheckboxApprover">
            <Form.Check
              style={{ marginTop: '1px' }}
              name="AsApprover"
              type="checkbox"
              label="Approver"
              checked={asFilterApprover}
              onChange={handleInputChange}
            />
          </Form.Group>
          }
        </div>
        <div className="col-md-1">
          <Button onClick={filterProjectPlan} title='Filter Project Plan' style={{ verticalAlign: 'center' }} variant="primary" type="submit">
            <i className="fas fa-filter"></i>
          </Button>
          <Button onClick={handleFilterReset} title='Reset Filter' style={{ marginLeft: '10px', marginTop: '17px', verticalAlign: 'center' }} variant="secondary" type="submit">
            <i className="fas fa-undo"></i>
          </Button>
        </div>
      </div>

      <div className='row'>
        <div className="card shadow mb-1">
          <div className="card-header py-1 d-flex flex-row align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold text-primary">Project Summary</h6>
            <div className="d-flex flex-row align-items-center justify-content-between">
              {!readOnlyUI && <div>
                <Link to={`/project-template-new`}>
                  <i className="fas fa-project-diagram"></i>
                  <span>Create New Project Plan</span>
                </Link>
              </div>
              }
              {!readOnlyUI && <div className="dropdown no-arrow">
                <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                  aria-labelledby="dropdownMenuLink">
                  <div className="dropdown-header">Dropdown Header:</div>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to={`/project-template-new`}>
                    <span>Create New Project Plan</span>
                  </Link>
                </div>
              </div>
              }
            </div>

          </div>
          {/*  <!-- Card Body --> */}
          <div className={loading ? 'card-body table-fade' : 'card-body'}>
            {loading ? (<LoadingIcon />) : ''}
            <div className="table-container" style={{ minHeight: '650px', maxHeight: '650px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
              <Table striped bordered hover style={{ fontSize: '12px', margin: '0px' }}>
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr>
                    <th style={{ width: '20%' }}>Title</th>
                    <th style={{ width: '20%' }}>Client Name</th>
                    <th>Deal Name</th>
                    <th>Status</th>
                    <th>Version</th>
                    <th>Offered Price</th>
                    <th>Estimated Price</th>
                    <th>Created By</th>
                    <th>Create Date</th>
                    <th>Modified By</th>
                    <th>Modified Date</th>
                  </tr>
                </thead>
                <tbody>
                  {allProjects.map((item) => (
                    <tr key={item.ProjectId}>
                      <td>
                        <Link to={`/project-dashboard/${item.ProjectId}`}>
                          {item.ProjectName ? item.ProjectName : "<go to details>"}
                        </Link>
                      </td>
                      <td>{item.ClientName}</td>
                      <td style={{ maxWidth: '200px', wordWrap: 'break-word' }}>{item?.HubspotDealId && item?.HubspotDealId > 0 ? `${item?.HubspotDealId} - ` : ''}{item.HubspotDealName}</td>
                      <td>{`${item.Status} (V${item.Version}) `}</td>
                      <td>{item.VersionName ?? ''}</td>
                      <td>{formatToUSCurrency(item.OfferedCost)}</td>
                      <td>{formatToUSCurrency(item.ALTCost)}</td>
                      <td>{item.CreatedBy}</td>
                      <td>{formatDateTimeAsString(item.CreateDate)}</td>
                      <td>{item.ModifiedBy}</td>
                      <td>{formatDateTimeAsString(item.ModifiedDate)}</td>
                    </tr>
                  ))}
                  {allProjects?.length == 0 && (
                    <tr>
                      <td colSpan={11}>
                        <div style={{ marginLeft: '40%', fontSize: '14pt', fontWeight: 'bold' }}>No project plan found.</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: '30%', marginTop: '30px' }}>
              <GridPagination
                totalRecords={totalRecords}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSummaryGrid;
