import React, { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { IRoleSkillBase, ISkillBase } from '../../data-model';
import { allMasterSkillLevel } from '../_services/initial-default-data';
import './multiselect-skill-dropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const SkillBaseMultiSelectDropdown: React.FC<{
    allRoleSkills?: ISkillBase[] | [],
    roleResourceId?: number | undefined,
    roleId?: number | undefined,
    selectedSkills: IRoleSkillBase[] | [],
    onSelectedSkill: (selectedSkill: IRoleSkillBase[]) => void;
    isControlDisabled?: boolean | false;
}> = ({ allRoleSkills, roleResourceId, roleId, selectedSkills, onSelectedSkill, isControlDisabled }) => {

    const [selectedSkillsOptions, setSelectedSkillsOptions] = useState<IRoleSkillBase[]>([]);
    const [filteredSkillBase, setFilteredSkillBase] = useState<ISkillBase[]>();
    const [attachedSkillFilter, setAttachedSkillFilter] = useState<string>('');

    const [displayText, setDisplayText] = useState<string>();

    let lastDisplayedCategory = '';

    useEffect(() => {
        setSelectedSkillsOptions(selectedSkills ?? []);
    }, [selectedSkills]);

    useEffect(() => {
        setFilteredSkillBase(allRoleSkills ?? [])
    }, [allRoleSkills]);

    useEffect(() => {
        onSelectedSkill(selectedSkillsOptions);
        setDisplayText(getSelectedOptionsLabels(selectedSkillsOptions ?? []));
    }, [selectedSkillsOptions]);

    useEffect(() => {
        if (attachedSkillFilter?.length > 0) {
            const filteredData = allRoleSkills?.filter((item) =>
                item.Skill?.toLowerCase().includes(attachedSkillFilter?.toLowerCase())
                || item.SkillCategory?.toLowerCase().includes(attachedSkillFilter?.toLowerCase())
            );
            setFilteredSkillBase(filteredData ?? []);
        } else {
            setFilteredSkillBase(allRoleSkills ?? []);
        }
    }, [attachedSkillFilter]);

    const handleSkillChange = (event: React.ChangeEvent<HTMLInputElement>, skill: ISkillBase) => {
        const { name, value, checked } = event.target;
        const isChecked = selectedSkillsOptions.some((item) => item.SkillBaseId === skill.SkillBaseId);
        if (isChecked && !checked) {
            setSelectedSkillsOptions(selectedSkillsOptions.filter((item) => item.SkillBaseId != skill.SkillBaseId));
        } else {
            let selectedSkill: IRoleSkillBase = {
                SkillBaseId: skill.SkillBaseId,
                RoleResourceId: roleResourceId,
                Level: null,
                Skill: skill,
                RoleSkillDBId: null
            };
            setSelectedSkillsOptions([...selectedSkillsOptions, selectedSkill]);
        }
    };

    const handleSkillLevelChange = async (event: React.ChangeEvent<HTMLSelectElement>, existingSkill: ISkillBase) => {
        const { name, value } = event.target;
        let selectedSkill = selectedSkillsOptions.filter((item) => item.SkillBaseId == existingSkill.SkillBaseId)[0];
        if (selectedSkill && selectedSkill.SkillBaseId != null && value) {
            let updatedSkill = { ...selectedSkill, Level: parseInt(value) }
            setSelectedSkillsOptions(prevSkills =>
                prevSkills.map(skill =>
                    skill.SkillBaseId == existingSkill.SkillBaseId ? updatedSkill : skill
                )
            );
        }
    }

    const getSelectedOptionsLabels = (selectedSkills: IRoleSkillBase[]) => {
        const labels = selectedSkills.map(optionSkill => {
            let level = allMasterSkillLevel?.filter(l => l.Value == optionSkill.Level)[0];
            return `${optionSkill?.Skill?.Skill} ${level?.Value ? '(' + level?.Name + ')' : ''}`;
        });

        return labels.join(', ');
    };


    return (<>
        <div className='row' >
            <div style={{ minHeight: '80px', maxHeight: '80px', overflowY: 'scroll' }}>
                <strong>Selectd Skill(s) :</strong> {displayText}
            </div>
        </div>
        <div className='row'>
            <div className="d-flex justify-content-between">
                <h3 style={{ backgroundColor: '#7261f2' }}>All Skill(s):</h3>
                <p className="pr-3">Display: <strong>{filteredSkillBase?.length} </strong> /<strong> {allRoleSkills?.length} </strong>  skill(s)</p>

                <Form.Control
                    type="text"
                    name="filterSkill"
                    placeholder='Search Skill'
                    value={attachedSkillFilter}
                    style={{ width: '50%' }}
                    onChange={(e) => setAttachedSkillFilter(e.target.value)}
                />
            </div>
        </div>
        <div className='row' style={{ height: '400px', overflowY: 'scroll' }}>
            <div style={{ padding: '2px', minWidth: '100%' }}>
                {filteredSkillBase?.map((skill) => {
                    let selectedSkill = selectedSkillsOptions.filter((item) => item.SkillBaseId === skill.SkillBaseId)[0];
                    let isRoleSelected = selectedSkill != undefined;

                    let displaySubCategory = false;
                    if (skill.SkillCategory != lastDisplayedCategory) {
                        displaySubCategory = true;
                        lastDisplayedCategory = skill.SkillCategory ?? 'N/A';
                    }

                    return (
                        <>
                            {displaySubCategory &&
                                <div className="d-flex"><FontAwesomeIcon icon={faChevronRight} />  <strong className='pl-2'>{`${skill.SkillCategory ?? ''}`}</strong></div>}

                            <div className='pl-4 d-flex justify-content-between'>
                                <Form.Check
                                    key={skill.SkillBaseId}
                                    disabled={isControlDisabled}
                                    type="checkbox"
                                    name='skill'
                                    id={`checkbox-${skill.SkillBaseId}`}
                                    label={skill.Skill}
                                    className="highlight-checkbox"
                                    title={skill.Description ?? ''}
                                    style={{ fontSize: '12pt' }}
                                    checked={isRoleSelected}
                                    onChange={(e) => handleSkillChange(e, skill)}
                                />
                                <select style={{ marginLeft: '20px', marginBottom: '10px' }}
                                    id="level" name="level"
                                    disabled={isControlDisabled || !isRoleSelected}
                                    value={selectedSkill?.Level ?? "-1"}
                                    title={allMasterSkillLevel.filter(f => f.Value == selectedSkill?.Level)[0]?.Description}
                                    onChange={(e) => handleSkillLevelChange(e, skill)}
                                >
                                    <option disabled value="-1">--Select--</option>
                                    {
                                        allMasterSkillLevel?.map((level, index) => (
                                            <option key={index} value={level.Value} title={`${level.Name}: ${level.Description}`}>
                                                {`${level.Value}-${level.Name}`}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    </>
    );
};

export default SkillBaseMultiSelectDropdown;
