import { useEffect, useState } from "react"
import { getPCRRateSummary } from "../_services/pcr-rates-svc";
import { IRole } from "../../data-model";
import { Button, Form, Modal, Table } from "react-bootstrap";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { initialDefaultRole } from "../_services/initial-default-data";

const RoleCreate: React.FC<{
    show: boolean;
    onClose: () => void;
    onSave: (updatedDetails: IRole) => void;
    initialRole: IRole;
}> = ({ show, onClose, initialRole, onSave }) => {
    const [formData, setFormData] = useState<IRole>(initialRole || initialDefaultRole);
    const [loading, setLoading] = useState(true);
    let pcrRateId: number | undefined = undefined;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    return (
        <>
            <Modal show={show} onHide={onClose} dialogClassName='modal-lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{initialRole ? 'Edit Role' : 'Create Role'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formRoleName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter role name"
                                name="Name"
                                value={formData?.Name ?? ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formRoleCode">
                            <Form.Label>Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter role name"
                                name="Code"
                                value={formData?.Code ?? ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formRoleGroup">
                            <Form.Label>Group</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter group name"
                                name="RoleGroup"
                                value={formData?.RoleGroup ?? ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formRoleDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                cols={55}
                                placeholder="Enter role description"
                                name="Description"
                                value={formData?.Description ?? ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formRoleIsActive">
                            <Form.Check
                                type="checkbox"
                                label="Is Active"
                                name="IsActive"
                                checked={formData.IsActive}
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        IsActive: e.target.checked,
                                    }))
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        <i className="fas fa-times"></i> Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        <i className="fas fa-save"></i>  Save Role
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
};

export default RoleCreate;
