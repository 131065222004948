import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Tabs, Tab } from 'react-bootstrap';
import { IEmployee, IPCRRoleRate, IProjectRolesResource, IRoleSkillBase } from '../../data-model';
import { getAllPCRRoleByYear, getPCRRateSummary } from '../_services/pcr-rates-svc';
import { toast } from 'react-toastify';
import { areObjectsEqual, calculateALTRate, calculateDiscount, formatDateTimeAsString, getRandomNumber, isProjectReadOnlyStatus, isUserRoleReadOnly } from '../../utilities/helper-function';
import { useProjectDataContext } from '../../data-context/project-data-context';
import AutocompleteSearch from '../../common/auto-complete/auto-complete-search';
import { getAllCWMemberData } from '../_services/master-data-svc';
import { useDelayDebounce } from '../../common/hooks/debounce';
import SkillBaseMultiSelectDropdown from './mutiselect-skill-dropdown';
import { allMasterSkillLevel } from '../_services/initial-default-data';
import { setOriginalNode } from 'typescript';

const RoleResourceCreate: React.FC<{
  show: boolean;
  onClose: () => void;
  onSave: (updatedDetails: IProjectRolesResource, copyResourceAtEffort: boolean) => boolean;
  initialRoleResource: IProjectRolesResource;
}> = ({ show, onClose, initialRoleResource, onSave }) => {

  const { allCurrentPCRRoleRateGblCtx, setAllCurrentPCRRoleRateGblCtx, refreshProjectStatusGblCtx,
    currentProjectInfoGblCtx, isRoleWillCreateAtEffortGblCtx, userAccessInfoGblCtx } = useProjectDataContext();

  const delayDebouncedValue = useDelayDebounce(100);

  const [currentResourceRole, setCurrentResourceRole] = useState<IProjectRolesResource>(initialRoleResource);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [isALTRateReadOnly, setIsALTRateReadOnly] = useState<boolean>(true);
  const [isSubContractorRateReadOnly, setIsSubContractorRateReadOnly] = useState<boolean>(true);
  const [saveRoleResourceDisabled, setSaveRoleResourceDisabled] = useState<boolean>(true);
  const [copyRoleResourceAtEffort, setCopyRoleResourceAtEffort] = useState<boolean>(false);
  const [validationRefresh, setValidationRefresh] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<number>(1);

  const [readonlyUI, setReadonlyUI] = useState<boolean>(false);

  const actionText = initialRoleResource.RoleResourceId != undefined && initialRoleResource.RoleResourceId > 0 ? "Edit" : "Add";
  const actionButtonText = initialRoleResource.RoleResourceId != undefined && initialRoleResource.RoleResourceId > 0 ? "Update" : "Save";

  let masterRoleSkill = allCurrentPCRRoleRateGblCtx.filter(f => f.PCRRoleRateId == currentResourceRole.PCRRoleRateId)[0]?.Role?.Skills;

  useEffect(() => {
    setCurrentResourceRole({
      ...initialRoleResource,
      Discount: calculateDiscount(initialRoleResource.STDRate, initialRoleResource.OfferedRate)
    });
    if (initialRoleResource.STDRate > 0) {
      setIsALTRateReadOnly(false)
    }
    if (initialRoleResource.RoleResourceId > 0) {
      setIsSubContractorRateReadOnly(false)
    }
    setCopyRoleResourceAtEffort(isRoleWillCreateAtEffortGblCtx);
  }, [initialRoleResource]);

  useEffect(() => {
    if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
      setReadonlyUI(isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles));
    }
  }, [refreshProjectStatusGblCtx]);

  useEffect(() => {
    setSaveRoleResourceDisabled(areObjectsEqual(initialRoleResource, currentResourceRole));
  }, [currentResourceRole]);

  useEffect(() => {
    if (validationRefresh && validationRefresh > 0) {
      validateFormData();
    }
  }, [validationRefresh]);

  useEffect(() => {
    if (allCurrentPCRRoleRateGblCtx.length == 0 && delayDebouncedValue > 0) {
      fetchAllPCRRates();
    }
  }, [delayDebouncedValue]);

  const fetchAllPCRRates = async () => {
    const data = await getAllPCRRoleByYear();
    if (data) {
      setAllCurrentPCRRoleRateGblCtx(data);
    }
  };

  const validateFormData = () => {
    const errors: { [key: string]: string } = {};
    setValidationErrors({});
    if (!currentResourceRole.PCRRoleRate?.Role?.Name) {
      errors.Role = 'Role is required';
    }
    // if (!currentResourceRole.Resource) {
    //   errors.Resource = 'Resource is required';
    // }
    if (currentResourceRole.ALTRate && currentResourceRole.ALTRate?.toString() === "NaN") {
      currentResourceRole.ALTRate = null;
    }
    if (currentResourceRole.Discount && currentResourceRole.Discount?.toString() === "NaN") {
      currentResourceRole.Discount = null;
    }

    if (currentResourceRole.Notes && (currentResourceRole.Notes.trim().length <= 0 || currentResourceRole.Notes.trim().length > 200)) {
      errors.Notes = 'Notes should be 0-200 charecter';
    }

    if (currentResourceRole.ALTRate != undefined && (currentResourceRole.ALTRate < 0.0001 || currentResourceRole.ALTRate > currentResourceRole.STDRate * 2)) {
      errors.ALTRate = `ALT Rate is should be between greater '0' and STD Rate i.e. ${currentResourceRole.STDRate * 2}`;
    }

    if (currentResourceRole.IsSubContractor && !isSubContractorRateReadOnly
      && (currentResourceRole.SubContractorFees?.toString() == '' || currentResourceRole.SubContractorFees == undefined || ((currentResourceRole.SubContractorFees && (currentResourceRole.SubContractorFees < 0.0001 || (currentResourceRole.SubContractorFees > 0 && currentResourceRole.SubContractorFees >= currentResourceRole.OfferedRate)))))) {
      errors.SubContractorFees = `Sub-Contractor Rate is should be between greater '0' and less Offered Rate i.e. ${currentResourceRole.OfferedRate}`;
    }

    if (currentResourceRole.Discount != undefined && (currentResourceRole.Discount <= -100 || currentResourceRole.Discount > 100)) {
      errors.Discount = `Discount(-)/Markup(+) should be between greater '-100%' and +100%`;
    }
    setValidationErrors(errors);

    return errors;
  }

  const handleSave = async (isCloseModalAfterSave: boolean | true) => {
    const errors = validateFormData();
    if (Object.keys(errors).length === 0) {
      try {
        if (isCloseModalAfterSave) {
          onClose();
          onSave(currentResourceRole, copyRoleResourceAtEffort);
        } else {
          let isSuccess = await onSave(currentResourceRole, copyRoleResourceAtEffort);
          if (isSuccess) {
            setCurrentResourceRole(initialRoleResource);
            setIsALTRateReadOnly(true);
            setIsSubContractorRateReadOnly(true);
            setSelectedTab(1);
          }
        }
      } catch (error) {
        return toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
      }
    } else {
      setValidationErrors(errors);
    }
  };

  const resetForm = () => {
    setCurrentResourceRole({
      ...initialRoleResource,
      Discount: calculateDiscount(initialRoleResource.STDRate, initialRoleResource.OfferedRate)
    });
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "Role") {
      handleRoleChange(event);
    }
    else {
      setCurrentResourceRole((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (name === "SubContractorFees" && value == '') {
      setCurrentResourceRole({
        ...currentResourceRole,
        SubContractorFees: null
      });
    }

    if (name === "ALTRate") {
      let altRate: number | null = null;
      let offeredRate: number = currentResourceRole.STDRate;
      if (value) {
        altRate = offeredRate = parseFloat(value);
      }
      setCurrentResourceRole({
        ...currentResourceRole,
        ALTRate: altRate,
        OfferedRate: offeredRate,
        Discount: calculateDiscount(currentResourceRole.STDRate, parseFloat(value))
      });
    }

    if (name === "Discount") {
      let discount: number | null = isNaN(parseFloat(value)) ? null : parseFloat(value);
      let altRate: number | null = null;
      let offeredRate: number = currentResourceRole.OfferedRate;
      altRate = offeredRate = calculateALTRate(currentResourceRole.STDRate, discount ?? 0)
      setCurrentResourceRole({
        ...currentResourceRole,
        ALTRate: altRate,
        OfferedRate: offeredRate,
        Discount: discount
      });
    }
    setValidationRefresh(getRandomNumber());
  };

  const handleResourcesSelected = (item: IEmployee | undefined, selectedText: string) => {
    setCurrentResourceRole((prevData) => ({
      ...prevData,
      ["Resource"]: (item != undefined ? item.fullName : selectedText) ?? '',
    }));
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedRole = event.target.value;
    const selectedRoleObj = allCurrentPCRRoleRateGblCtx.find((role) => role.Role.RoleId === parseInt(selectedRole));

    if (selectedRoleObj) {
      setIsALTRateReadOnly(false);
      setIsSubContractorRateReadOnly(false);
      setCurrentResourceRole({
        ...currentResourceRole,
        PCRRoleRateId: selectedRoleObj.PCRRoleRateId,
        PCRRoleRate: selectedRoleObj,
        STDRate: selectedRoleObj.Rate,
        ALTRate: null,
        OfferedRate: selectedRoleObj.Rate,
        Discount: null,
        SelectedSkills: []
      });
    }
    else {
      setCurrentResourceRole({
        ...currentResourceRole,
        PCRRoleRate: selectedRoleObj,
        SelectedSkills: []
      });
    }
  };

  const handleSelectedSkill = (selectedSkills: IRoleSkillBase[]) => {
    setCurrentResourceRole({
      ...currentResourceRole,
      SelectedSkills: selectedSkills
    });
  }

  const handleSubcontractorClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked == false) {
      setCurrentResourceRole({
        ...currentResourceRole,
        SubContractorFees: null
      });
    }
    setCurrentResourceRole({
      ...currentResourceRole,
      IsSubContractor: event.target.checked,
    });
  }

  return (<div>
    <Modal onClick={handleModalClick} show={show} onHide={onClose} onClose={onClose} centered backdrop="static" dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>{actionText} Role-Resource:</Modal.Title>
        {!readonlyUI && <h6 style={{ marginLeft: '50px' }}>PCR Year: {allCurrentPCRRoleRateGblCtx[0]?.Year}</h6>}
      </Modal.Header>
      <Modal.Body style={{ width: '800px', minHeight: '350px', maxHeight: '650px' }}>
        <Form>
          <Tabs defaultActiveKey={selectedTab.toString()} id="role-rate" className="mb-1">
            <Tab eventKey="1" title="Role">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="role">
                    <Form.Label>Role</Form.Label>
                    <Form.Control as="select" name="Role"
                      isInvalid={!!validationErrors.Role}
                      disabled={readonlyUI}
                      value={currentResourceRole.PCRRoleRate?.Role.RoleId ?? -1}
                      title={`PCR Year: ${currentResourceRole?.PCRRoleRate?.Year ?? ''}, ${currentResourceRole.PCRRoleRate?.Role?.Name ?? ''} | Description: ${currentResourceRole.PCRRoleRate?.Role?.Description ?? ''}`}
                      onChange={handleInputChange}>
                      <option value="-1" disabled>Select a Role</option>
                      {
                        allCurrentPCRRoleRateGblCtx?.map((pcr, index) => (
                          <option key={index} value={pcr.Role.RoleId} title={pcr.Role.Description}>
                            {pcr.Role.Name}
                          </option>
                        ))
                      }
                    </Form.Control>
                    {
                      validationErrors.Role && <p style={{ color: 'red' }} className="error-message">{validationErrors.Role}*</p>
                    }
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="resource">
                    <Form.Label>Resource</Form.Label>
                    <AutocompleteSearch
                      OnItemSelected={handleResourcesSelected}
                      SelectedInitialItem={currentResourceRole.Resource}
                      PlaceHolder=''
                      APICallback={(searchText) => getAllCWMemberData(searchText)}
                      AllowFreeText={true}
                      ShowVaidationError={!!validationErrors.Resource}
                      DisplayProperty={(item) => item.fullName ?? 'fullName'}
                      isDisabled={readonlyUI}
                    />
                    {
                      validationErrors.Resource && <p style={{ color: 'red' }} className="error-message">{validationErrors.Resource}*</p>
                    }
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="stdRate">
                    <Form.Label>STD Rate</Form.Label>
                    <Form.Control
                      type="text"
                      name="STDRate"
                      value={currentResourceRole.STDRate}
                      readOnly
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="altRate">
                    <Form.Label>ALT Rate</Form.Label>
                    <Form.Control
                      type="number"
                      name="ALTRate"
                      disabled={readonlyUI}
                      autoComplete='off'
                      value={currentResourceRole.ALTRate ?? ''}
                      min={0}
                      max={currentResourceRole.STDRate}
                      onChange={handleInputChange}
                      isInvalid={!!validationErrors.ALTRate}
                      readOnly={isALTRateReadOnly}
                    />
                    {
                      validationErrors.ALTRate && <p style={{ color: 'red' }} className="error-message">{validationErrors.ALTRate}*</p>
                    }
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-6">
                  <Form.Group controlId="offeredRate">
                    <Form.Label>Offered Rate</Form.Label>
                    <Form.Control
                      type="number"
                      name="Offered Rate"
                      value={currentResourceRole.OfferedRate}
                      readOnly
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="discount">
                    <Form.Label>Discount(-) / Markup(+) %</Form.Label>
                    <Form.Control
                      type="number"
                      disabled={readonlyUI}
                      name="Discount"
                      value={currentResourceRole.Discount ?? ''}
                      readOnly={isALTRateReadOnly}
                      onChange={handleInputChange}
                      isInvalid={!!validationErrors.Discount}
                    />
                    {
                      validationErrors.Discount && <p style={{ color: 'red' }} className="error-message">{validationErrors.Discount}*</p>
                    }
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-12">
                  <Form.Group controlId="notes">
                    <Form.Label>Note:</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="Notes"
                      disabled={readonlyUI}
                      value={currentResourceRole.Notes ?? ''}
                      onChange={handleInputChange}
                      isInvalid={!!validationErrors.Notes}
                      rows={2}
                      cols={200}
                      placeholder="Enter user note here..."
                    />
                    {
                      validationErrors.Notes && <p style={{ color: 'red' }} className="error-message">{validationErrors.Notes}*</p>
                    }
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-6">
                  <div className="d-flex justify-content-between" style={{ fontSize: '10pt', fontWeight: 'bold', flex: 1, textAlign: 'left' }}>
                    <Form.Check
                      type="checkbox"
                      className="highlight-checkbox"
                      style={{ marginTop: '33px', fontSize: '12pt' }}
                      name="IsSubContractor"
                      checked={currentResourceRole.IsSubContractor ?? false}
                      label="Check If Subcontractor"
                      onChange={(e) => handleSubcontractorClicked(e)}
                      disabled={readonlyUI || isSubContractorRateReadOnly}
                    />
                  </div>
                </div>
                {currentResourceRole.IsSubContractor && <div className="col-md-6">
                  <Form.Group controlId="SubContractorFees">
                    <Form.Label>Sub Contractor Cost</Form.Label>
                    <Form.Control
                      type="number"
                      name="SubContractorFees"
                      disabled={readonlyUI}
                      value={currentResourceRole.SubContractorFees ?? ''}
                      min={0}
                      max={currentResourceRole.STDRate}
                      onChange={handleInputChange}
                      isInvalid={!!validationErrors.SubContractorFees}
                      readOnly={isSubContractorRateReadOnly}
                    />
                    {
                      validationErrors.SubContractorFees && <p style={{ color: 'red' }} className="error-message">{validationErrors.SubContractorFees}*</p>
                    }
                  </Form.Group>
                </div>
                }
              </div>
              {!readonlyUI && <div>
                <div className="d-flex justify-content-between" style={{ fontSize: '10pt', fontWeight: 'bold', flex: 1, textAlign: 'left' }}>
                  <Form.Check
                    type="checkbox"
                    className="highlight-checkbox"
                    style={{ marginTop: '33px', fontSize: '12pt' }}
                    name="roleResourceAtEffort"
                    checked={copyRoleResourceAtEffort}
                    label="Copy/Update Role onto Resource Plan"
                    onChange={(e) => setCopyRoleResourceAtEffort(e.target.checked)}
                  />
                </div>
              </div>
              }
            </Tab>
            <Tab eventKey="2" disabled={!((currentResourceRole?.PCRRoleRateId ?? 0) > 0)} title="Skills">
              <div className='row'>
                <SkillBaseMultiSelectDropdown
                  allRoleSkills={masterRoleSkill ?? []}
                  isControlDisabled={readonlyUI}
                  roleResourceId={currentResourceRole.RoleResourceId}
                  selectedSkills={currentResourceRole?.SelectedSkills ?? []}
                  onSelectedSkill={(selectedSkills) => handleSelectedSkill(selectedSkills)}
                />
              </div>
            </Tab>
          </Tabs>
        </Form>
      </Modal.Body>
      {!readonlyUI && <Modal.Footer className="d-flex justify-content-between">
        <div>
          {initialRoleResource.RoleResourceId > 0 &&
            <p style={{ fontSize: '8pt' }}><strong>Created By:</strong>  {currentResourceRole.CreatedBy}<strong>   Date:</strong>  {formatDateTimeAsString(currentResourceRole.CreateDate)}</p>
          }
          {initialRoleResource.RoleResourceId > 0 && currentResourceRole.ModifiedBy &&
            <p style={{ fontSize: '8pt' }}><strong>Modified By:</strong>  {currentResourceRole.ModifiedBy}<strong>   Date:</strong>  {formatDateTimeAsString(currentResourceRole.ModifiedDate)}</p>
          }
        </div>
        <div>
          <Button style={{ marginTop: '15px' }} variant="secondary" onClick={resetForm}>
            <i className="fas fa-undo"></i> Reset
          </Button>
          <Button style={{ marginLeft: '10px', marginTop: '15px' }} variant="secondary" onClick={onClose}>
            <i className="fas fa-times"></i>  Close
          </Button>
          <Button style={{ marginLeft: '10px', marginRight: '10px' }} disabled={saveRoleResourceDisabled} variant="primary" onClick={(e) => handleSave(true)}>
            <i className="fas fa-save"></i>  {actionButtonText}
          </Button>
          {currentResourceRole.RoleResourceId == 0 && <Button style={{ marginLeft: '10px', marginRight: '10px' }} disabled={saveRoleResourceDisabled} variant="primary" onClick={(e) => handleSave(false)}>
            <i className="fas fa-save"></i>  Save & Add New
          </Button>
          }
        </div>
      </Modal.Footer>
      }
    </Modal>
  </div>
  );
};

export default RoleResourceCreate;

