import { Form } from "react-bootstrap";
import { IAuditTransaction } from "../../data-model";
import { useEffect, useState } from "react";


const AuditInfo: React.FC<{ auditData: IAuditTransaction[] }> = ({ auditData }) => {

    const [auditTransactionFilteredlData, setAuditTransactionFilteredData] = useState<IAuditTransaction[]>();
    const [fieldFilter, setFieldFilter] = useState<string>('');

    useEffect(() => {
        if (fieldFilter?.length > 0) {
            const filteredData = auditData?.filter((item) =>
                item.PropertyName?.toLowerCase().includes(fieldFilter?.toLowerCase()));
            setAuditTransactionFilteredData(filteredData);
        } else {
            setAuditTransactionFilteredData(auditData);
        }
    }, [fieldFilter]);

    useEffect(() => {
        setAuditTransactionFilteredData(auditData)
    }, [auditData]);

    return (
        <>
            <div className="table-container" style={{ minHeight: '500px', maxHeight: '500px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
                <table className='table striped bordered hover' style={{ fontSize: '12px', margin: '0px' }}>
                    <thead style={{ position: 'sticky', top: '0' }}>
                        <tr>
                            <th>Transaction Id</th>
                            <th>
                                <Form.Control
                                    type="text"
                                    name="FieldName"
                                    placeholder='Filter Field'
                                    value={fieldFilter}
                                    onChange={(e) => setFieldFilter(e.target.value)}
                                />
                                Field
                            </th>
                            <th>Old Value</th>
                            <th>New Value</th>
                            <th>Changed By</th>
                            <th>Changed Time</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auditTransactionFilteredlData?.map((item, index) => (
                            <tr key={index}>
                                <td>{item.TransactionId}</td>
                                <td>{item.PropertyName}</td>
                                <td>{item.OldValue}</td>
                                <td>{item.NewValue}</td>
                                <td>{item.FullName}</td>
                                <td>{item.ExecutionTime ? item.ExecutionTime.toLocaleString() : ''}</td>
                                <td>{item.Event}</td>
                            </tr>
                        ))}
                        {auditTransactionFilteredlData?.length == 0 && (
                            <tr>
                                <td colSpan={7}>
                                    <div style={{ marginLeft: '40%', fontSize: '14pt', fontWeight: 'bold' }}>No audit record(s) found.</div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default AuditInfo;