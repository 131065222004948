import { useIsAuthenticated } from "@azure/msal-react";
import { msalInstance } from "../../..";
import AccessDeniedComponent from "./access-denied";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { IsStringExists } from "../../utilities/helper-function";
import IUserRoleAccessType from "../../data-model/user-role-access-type";
import { useProjectDataContext } from "../../data-context/project-data-context";
import SessionTimedOutComponent from "./session-timed-out";

export const ValidateAuthentication = ({ children }: { children: JSX.Element }) => {
  let isAuthenticated = useIsAuthenticated();
  const { userAccessInfoGblCtx, setUserAccessInfoGblCtx } = useProjectDataContext();
  const [isValidAccess, setIsValidAccess] = useState<boolean>(false);

  useEffect(() => {
    fetchUserRole();
  }, []);

  const fetchUserRole = async () => {
    try {
      const account = await msalInstance.getAllAccounts()[0];
      const accessTokenRequest = {
        scopes: (window as any).REACT_APP_AZURE_AD_SCOPE?.split(','),
        account: account,
      };
      let res = await msalInstance.acquireTokenSilent(accessTokenRequest);
      const decodedToken: any = jwtDecode(res.accessToken);
      if (decodedToken) {
        const tokenRoles: string[] = decodedToken.roles;
        let accessDataInfo: IUserRoleAccessType = {
          UserName: decodedToken?.unique_name,
          AssignedRoles: tokenRoles,
          IsAdminUser: IsStringExists(tokenRoles, (window as any).REACT_APP_APPLICATION_ADMIN_ROLE.split(',')),
          IsRegularUser: IsStringExists(tokenRoles, (window as any).REACT_APP_APPLICATION_ACCESS_ROLE.split(',')),
          IsSuperAdminUser: IsStringExists(tokenRoles, (window as any).REACT_APP_APPLICATION_SUPER_ADMIN_ROLE.split(',')),
          IsRMOAdminUser: IsStringExists(tokenRoles, (window as any).REACT_APP_APPLICATION_RMO_ADMIN_ROLE.split(',')),
          //IsReadOnlyUser: IsStringExists(tokenRoles, (window as any).REACT_APP_APPLICATION_READ_ONLY_ROLE.split(',')),
        }
        setUserAccessInfoGblCtx(accessDataInfo);
        let isValidAccess = accessDataInfo.IsAdminUser || accessDataInfo.IsRegularUser || accessDataInfo.IsSuperAdminUser || accessDataInfo.IsRMOAdminUser;
        setIsValidAccess(isValidAccess);
      } else {
        setIsValidAccess(false);
      }
    } catch (error) {
      console.error('error occurred at fetchUserRole', error);
      setIsValidAccess(false);
    }
  }

  return (
    (!isAuthenticated) ? <SessionTimedOutComponent /> :
      (!isValidAccess) ? <AccessDeniedComponent /> : children
  );
}