import { useEffect, useState } from "react";
import { ISkillBase } from "../../data-model";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { initialDefaultSkillBase } from "../_services/initial-default-data";
import AutocompleteSearch from "../../common/auto-complete/auto-complete-search";
import { getAllMatchedSkillCategory } from "../_services/master-data-svc";
import ISkillCategory from "../../data-model/skill-category";

const SkillBaseCreate: React.FC<{
    show: boolean;
    onClose: () => void;
    onSave: (updatedDetails: ISkillBase) => void;
    initialSkills: ISkillBase;
}> = ({ show, onClose, initialSkills: initialRole, onSave }) => {

    const [formData, setFormData] = useState<ISkillBase>(initialRole || initialDefaultSkillBase);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    const handleSkillCategorySelected = (item: ISkillCategory | undefined, selectedText: string) => {
        setFormData((prevData) => ({
            ...prevData,
            ["SkillCategory"]: (item != undefined ? item.Name : selectedText) ?? '',
        }));
    };

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{initialRole ? 'Edit Role' : 'Create Role'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formSkill">
                            <Form.Label>Skill</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter skill name"
                                name="Skill"
                                value={formData?.Skill ?? ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="skillCategory">
                            <Form.Label>Skill Category</Form.Label>
                            <AutocompleteSearch
                                OnItemSelected={handleSkillCategorySelected}
                                SelectedInitialItem={formData.SkillCategory ?? ''}
                                PlaceHolder='Enter skill Category'
                                APICallback={(searchText) => getAllMatchedSkillCategory(searchText)}
                                AllowFreeText={true}
                                DisplayProperty={(item) => item.Name ?? 'Name'}
                                isDisabled={false}
                                ShowVaidationError={false}
                            />
                        </Form.Group>
                        <Form.Group controlId="formSkillDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                cols={55}
                                placeholder="Enter skill description"
                                name="Description"
                                value={formData?.Description ?? ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formSkillIsActive">
                            <Form.Check
                                type="checkbox"
                                label="Is Active"
                                name="IsActive"
                                checked={formData.IsActive ?? false}
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        IsActive: e.target.checked,
                                    }))
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        <i className="fas fa-times"></i> Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        <i className="fas fa-save"></i> Save Skill
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default SkillBaseCreate;