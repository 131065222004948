import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProjectRolesResource, IRoleSkillBase } from "../../data-model"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import SkillBaseMultiSelectDropdown from "./mutiselect-skill-dropdown";
import { useProjectDataContext } from "../../data-context/project-data-context";

const SkillsDetails: React.FC<{ resource: IProjectRolesResource | undefined | null }> = ({ resource }) => {
    let allSkills = resource?.SelectedSkills?.map(m => m.Skill);
    const handleSelectedSkill = (selectedSkills: IRoleSkillBase[]) => {

    }
    return (
        <>
            <SkillBaseMultiSelectDropdown
                allRoleSkills={allSkills}
                isControlDisabled={false}
                roleResourceId={resource?.RoleResourceId}
                selectedSkills={resource?.SelectedSkills ?? []}
                onSelectedSkill={(selectedSkills) => handleSelectedSkill(selectedSkills)}
            />
        </>
    )
}

export default SkillsDetails;