import { useEffect, useState } from "react";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { IProjectVersion } from "../../data-model";
import { getAllProjectVersion } from "../_services/dashboard-svc";
import { useDelayDebounce } from "../../common/hooks/debounce";
import { getRandomNumber, isProjectVersionCreateVisible, isUserRoleReadOnly } from "../../utilities/helper-function";
import { useNavigate } from "react-router-dom";
import ProjectDuplicateModal from './project-version-create-modal';

import { Button } from "react-bootstrap";

const ManageProjectVersion: React.FC<{ projectId?: number }> = ({ projectId }) => {
    const { currentProjectIdGblCtx, userAccessInfoGblCtx, currentProjectInfoGblCtx,
        currentProjectParentIdGblCtx, refreshProjectStatusGblCtx, setRefreshProjectStatusGblCtx,
        refreshProjectVersionGblCtx } = useProjectDataContext();

    const [projectVersions, setprojectVersions] = useState<IProjectVersion[]>([]);
    const [selectedProjectVersion, setSelectedProjectVersion] = useState<IProjectVersion>();
    const [displayParentLink, setDisplayParentLink] = useState<boolean>();
    const [showProjectDuplicateModal, setShowProjectDuplicateModal] = useState<boolean>(false);

    const delayDebouncedValue = useDelayDebounce(20);
    const navigate = useNavigate();


    useEffect(() => {
        setprojectVersions([]);
        if (delayDebouncedValue > 0) {
            fetchProjectVersions();
        }
    }, [delayDebouncedValue]);

    useEffect(() => {
        if (refreshProjectVersionGblCtx && refreshProjectVersionGblCtx > 0 && currentProjectIdGblCtx && currentProjectIdGblCtx > 0) {
            fetchProjectVersions();
        }
    }, [refreshProjectVersionGblCtx]);

    useEffect(() => {
        if (refreshProjectVersionGblCtx && refreshProjectVersionGblCtx > 0 && currentProjectIdGblCtx && currentProjectIdGblCtx > 0) {
            setSelectedProjectVersion(projectVersions.filter(p => p.ProjectId == currentProjectIdGblCtx)[0]);
        }
    }, [refreshProjectStatusGblCtx]);

    useEffect(() => {
        if (currentProjectIdGblCtx && currentProjectIdGblCtx > 0) {
            setSelectedProjectVersion(projectVersions.filter(p => p.ProjectId == currentProjectIdGblCtx)[0]);
        }
    }, [currentProjectIdGblCtx, currentProjectParentIdGblCtx]);

    useEffect(() => {
        if (currentProjectParentIdGblCtx && currentProjectParentIdGblCtx > 0) {
            setDisplayParentLink(true);
        } else {
            setDisplayParentLink(false);
        }
    }, [currentProjectParentIdGblCtx]);

    const fetchProjectVersions = async () => {
        try {
            if (currentProjectIdGblCtx && currentProjectIdGblCtx > 0) {
                let [totalRecords, data] = await getAllProjectVersion(currentProjectParentIdGblCtx ?? currentProjectIdGblCtx);
                if (data && totalRecords > 0) {
                    setprojectVersions(data);
                    setSelectedProjectVersion(data.filter(p => p.ProjectId == currentProjectIdGblCtx)[0]);
                    setRefreshProjectStatusGblCtx(getRandomNumber());
                }
            }
        } catch (error) {
            console.error('Error fetching versions:', error);
        }
    };

    const handleProjectVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        let selectedProject = parseInt(event.target.value);
        if (selectedProject == -1) {
            setShowProjectDuplicateModal(true);
        }

        if (selectedProject > 0) {
            let selectedVersion = projectVersions.filter(p => p.ProjectId == selectedProject)[0];
            if (selectedVersion) {
                setSelectedProjectVersion(selectedVersion);
                //setCurrentProjectIdGblCtx(selectedVersion.ProjectId); //Commented as page is hanging temporary fix
                navigate(`/project-dashboard/${selectedProject}`);
            }
        }
    };

    const toggleProjectDuplicateModal = () => {
        setShowProjectDuplicateModal(!showProjectDuplicateModal);
    };

    return (
        <>
            <table>
                <tr>
                    <div>
                        <ProjectDuplicateModal show={showProjectDuplicateModal} onClose={toggleProjectDuplicateModal} projectId={projectId ?? 0} parentProjectId={currentProjectInfoGblCtx?.ParentProjectId ?? 0} />
                    </div>
                    <td style={{ width: '100%' }}>

                        {currentProjectIdGblCtx && currentProjectIdGblCtx > 0 && (
                            <div className='d-flex justify-content-center'>
                                <label style={{ fontWeight: 'bold', fontSize: '12pt' }} htmlFor="version">Version:</label>
                                <select style={{ marginLeft: '10px' }} id="version" name="version" disabled={!currentProjectIdGblCtx}
                                    value={selectedProjectVersion?.ProjectId} onChange={handleProjectVersionChange} title={`${selectedProjectVersion?.VersionName ?? 'Main Version'} | ${selectedProjectVersion?.Status ?? ''} ${currentProjectInfoGblCtx?.ParentProjectId && currentProjectInfoGblCtx?.ParentProjectId > 0 ? '|' + selectedProjectVersion?.Comment : ''}`}>
                                    <option disabled value="">-Select-</option>
                                    {projectVersions.map((version) => {
                                        let defaultText = version.IsDefaultPlan == true ? `(Default)` : '';
                                        return (
                                            <option key={version.ProjectId} value={version.ProjectId} title={`${version.VersionName ?? 'Main Version'} | V${version.Version} | ${version.Status ?? ''} | ${version.Comment ?? ''}`}>
                                                {`${version.VersionName ?? 'Main Version'}${defaultText} | (${version.Status})`}
                                            </option>
                                        )
                                    })}
                                    {isProjectVersionCreateVisible(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) && !isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles) &&
                                        <option value={-1} title="Create New Version">
                                            Create New Version
                                        </option>
                                    }
                                </select>
                            </div>
                        )}
                        {/* {displayParentLink && (
                            <a href='#' onClick={setCurrentProjectIdOnClick}>Go to parent plan<i></i></a>
                        )} */}
                    </td>
                </tr>
            </table>
        </>
    );
}

export default ManageProjectVersion;