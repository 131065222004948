import { useEffect, useState } from "react";
import { useDelayDebounce } from "../../common/hooks/debounce";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import { Button, Form } from "react-bootstrap";
import { IRole, ISkillBase } from "../../data-model";
import { getSkillBaseDetailsById, attachRoleToSkillBase, detachRoleFromSkillBase, getAllRoles, getAllSkills } from "../_services/pcr-rates-svc";
import EfforHoursTextbox from "../project-effort-estimate-manage/effort-hours-textbox";

const SkillDetails: React.FC<{}> = ({ }) => {
    const [allDefaultRoles, setAllDefaultRoles] = useState<IRole[]>([]);
    const [selectedRole, setSelectedRole] = useState<ISkillBase | null>();
    const [allSelectedRoles, setAllSelectedRoles] = useState<IRole[]>([]);
    const [allDeSelectedRoles, setAllDeSelectedRoles] = useState<IRole[]>([]);
    const [loadingSelectedRole, setLoadingSelectedRole] = useState(true);
    const [loadingDefaultRole, setLoadingDefaultRole] = useState(true);

    const delayDebouncedSkills = useDelayDebounce(100);
    const delayDebouncedRole = useDelayDebounce(90);

    const { skillId } = useParams<{ skillId: string }>();

    let lastGroupNameLeft = ""
    let lastGroupNameRight = ""

    let selectedSkillId: number;
    if (skillId != undefined)
        selectedSkillId = parseInt(skillId);
    else
        selectedSkillId = 0;

    useEffect(() => {
        if (selectedSkillId > 0 && delayDebouncedRole > 0) {
            fetchSkillDetails();
        }

    }, [delayDebouncedRole]);

    const fetchSkillDetails = async () => {
        if (selectedSkillId > 0) {
            setLoadingSelectedRole(true);
            let data = await getSkillBaseDetailsById(selectedSkillId);
            if (data) {
                setSelectedRole(data);
            }
            setLoadingSelectedRole(false);
        }
    }

    useEffect(() => {
        if (delayDebouncedSkills > 0 && allDefaultRoles.length == 0) {
            fetchAllDefaultSkills();
        }
    }, [delayDebouncedSkills]);

    const fetchAllDefaultSkills = async () => {
        if (allDefaultRoles.length == 0) {
            setLoadingDefaultRole(true);
            let skills = await getAllRoles();
            if (skills) {
                setAllDefaultRoles(skills ?? []);
            }
            setLoadingDefaultRole(false);
        }
    }

    const handleAttach = async () => {
        if (allSelectedRoles.length > 0) {
            let [isSuccess, message] = await attachRoleToSkillBase(selectedSkillId, allSelectedRoles);

            if (isSuccess) {
                toast.success("Attached Skill Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchSkillDetails();
                setAllSelectedRoles([]);
                //setAllDeSelectedSkills([]);
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
            setLoadingSelectedRole(false);
        }
    };

    const handleDetach = async () => {
        if (allDeSelectedRoles.length > 0) {
            let [isSuccess, message] = await detachRoleFromSkillBase(selectedSkillId, allDeSelectedRoles);

            if (isSuccess) {
                toast.success("Removed Skill Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchSkillDetails();
                //setAllSelectedSkills([]);
                setAllDeSelectedRoles([]);
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
            setLoadingSelectedRole(false);
        }
    };

    const handleSelectedRoleChange = (event: React.ChangeEvent<HTMLInputElement>, role: IRole) => {
        const { name, value, checked } = event.target;
        if (!checked) {
            const isExist = allDeSelectedRoles.some((item) => item.RoleId == role.RoleId);
            if (isExist) {
                setAllDeSelectedRoles(allDeSelectedRoles.filter((item) => item.RoleId != role.RoleId));
            }
        }
        else {
            const isExist = allDeSelectedRoles.some((item) => item.RoleId == role.RoleId);
            if (!isExist) {
                setAllDeSelectedRoles([...allDeSelectedRoles, role]);
            }
        }
    };

    const handleDefaultRoleChange = (event: React.ChangeEvent<HTMLInputElement>, role: IRole) => {
        const { name, value, checked } = event.target;
        if (!checked) {
            const isExist = allSelectedRoles.some((item) => item.RoleGroup == role.RoleGroup);
            if (isExist) {
                setAllSelectedRoles(allSelectedRoles.filter((item) => item.RoleGroup != role.RoleGroup));
            }
        }
        else {
            const isExist = allSelectedRoles.some((item) => item.RoleGroup == role.RoleGroup);
            if (!isExist) {
                let selectedRole = allDefaultRoles.filter((item) => item.RoleGroup == role.RoleGroup);
                setAllSelectedRoles([...allSelectedRoles, ...selectedRole]);
            }
        }
    };

    return (
        <>
            <div>
                <center><h2>Skill Details</h2></center>

                <p><strong>Skill Name:</strong> {selectedRole?.Skill ?? ''}</p>
                <p><strong>Description:</strong> {selectedRole?.Description ?? ''}</p>
                <div className="row">
                    <Link to={`/skill-base-summary`}>
                        All Skill(s)
                    </Link>
                </div>
                <div className='row' style={{ border: '1px solid grey' }}>
                    <div className="col-xl-5 col-md-5 col-lg-5" style={{ borderRight: '2px solid grey' }}>
                        <h3 style={{ backgroundColor: 'grey' }}>Attached Role(s):</h3>
                        <div style={{ marginLeft: '20px', marginTop: '5px', overflowY: 'scroll', minHeight: '400px', maxHeight: '400px' }}>
                            {loadingSelectedRole ? (<LoadingIcon />) : ''}
                            {selectedRole?.Roles?.map((item) => {
                                let isRoleDeSelected: boolean = allDeSelectedRoles.some((s) => item.RoleId == s.RoleId);
                                let isSameGroup = lastGroupNameLeft.toLowerCase() === item.RoleGroup.toLowerCase();
                                lastGroupNameLeft = isSameGroup ? lastGroupNameLeft : item.RoleGroup
                                return <div>
                                    {!isSameGroup && <div>
                                        <strong>{lastGroupNameLeft}</strong>
                                    </div>
                                    }
                                    <div style={{ paddingLeft: '20px' }}>
                                        <Form.Check
                                            style={{ marginTop: '3px', fontSize: '15pt' }}
                                            key={item.RoleId}
                                            disabled={false}
                                            type="checkbox"
                                            name='skill'
                                            label={`${item.Name}`}
                                            checked={isRoleDeSelected}
                                            onChange={(e) => handleSelectedRoleChange(e, item)}
                                        />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="col-xl-1 col-md-1 col-lg-1" style={{ marginTop: '180px' }}>
                        <Button disabled={allSelectedRoles.length == 0} variant="primary" style={{ width: '100px' }} onClick={handleAttach}>
                            {'<--'}
                        </Button>
                        <Button disabled={allDeSelectedRoles.length == 0} variant="primary" style={{ marginTop: '10px', width: '100px' }} onClick={handleDetach}>
                            {'-->'}
                        </Button>
                    </div>
                    <div className="col-xl-5 col-md-5 col-lg-5" style={{ borderLeft: '2px solid grey' }}>
                        <h3 style={{ backgroundColor: 'green' }}>Available Role(s):</h3>
                        <div style={{ marginLeft: '20px', marginTop: '5px', overflowY: 'scroll', minHeight: '400px', maxHeight: '400px' }}>
                            {loadingSelectedRole ? (<LoadingIcon />) : ''}
                            {allDefaultRoles?.map((item) => {
                                let isExist1 = selectedRole?.Roles?.some((s) => item.RoleId == s.RoleId);
                                if (!isExist1) {
                                    let isRoleSelected: boolean = allSelectedRoles.some((s) => item.RoleId == s.RoleId);
                                    let isSameGroup = lastGroupNameRight.toLowerCase() === item.RoleGroup.toLowerCase();
                                    lastGroupNameRight = isSameGroup ? lastGroupNameRight : item.RoleGroup
                                    return <div>
                                        {!isSameGroup && <div>
                                            <strong>-{lastGroupNameRight}</strong>
                                        </div>
                                        }
                                        <div style={{ paddingLeft: '20px' }}>
                                            <Form.Check
                                                style={{ marginTop: '3px', fontSize: '15pt' }}
                                                key={item.RoleId}
                                                disabled={false}
                                                type="checkbox"
                                                name='skill'
                                                label={`${item.Name}`}
                                                checked={isRoleSelected}
                                                onChange={(e) => handleDefaultRoleChange(e, item)}
                                            />
                                        </div>
                                    </div>
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Link to={`/skill-base-summary`}>
                        All Skill(s)
                    </Link>
                </div>
            </div >
        </>
    );
};

export default SkillDetails;