import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import RoleCreate from "../role-manage/role-create";
import { toast } from "react-toastify";
import ConfirmationModal from "../../common/confirmation-dialog";
import { IApprover } from "../../data-model/approver";
import { useDelayDebounce } from "../../common/hooks/debounce";
import { deleteApprover, getAllApprovers, saveNewApprover, updateApprover } from "../_services/approval-svc";
import ApproverCreate from "./approver-create";
import { initialDefaultApprover } from "../_services/initial-default-data";

const ApproverSummary: React.FC<{}> = () => {
    const [showModal, setShowModal] = useState(false);
    const [allApprovers, setAllApprovers] = useState<IApprover[]>();
    const [loading, setLoading] = useState(true);

    const [currentApprover, setCurrentApprover] = useState<IApprover | null>();
    const [recordToDeleteMessage, setRecordToDeleteMessage] = useState<string>('');
    const [showApproverDeleteModal, setShowApproverDeleteModal] = useState(false);
    const delayDebouncedValue = useDelayDebounce(100);

    useEffect(() => {
        if (delayDebouncedValue) {
            fetchAllApprovers();
        }
    }, [delayDebouncedValue]);

    const fetchAllApprovers = async () => {
        setLoading(true);
        const data = await getAllApprovers();
        if (data) {
            setAllApprovers(data);
        } else {
            setAllApprovers([]);
        }
        setLoading(false);
    };

    const toggleModal = () => {
        setCurrentApprover(null);
        setShowModal(!showModal);
    };

    const editApprover = (approver: IApprover) => {
        setShowModal(!showModal);

        setCurrentApprover({
            ...approver
        });
    };

    const saveApprover = async (approver: IApprover) => {
        let [isSuccess, message] = [false, ''];
        if (approver.Id && approver.Id > 0) {
            [isSuccess, message] = await updateApprover(approver);
        }
        else {
            approver.Id = 0;
            approver.IsActive = approver.IsActive ?? false;
            [isSuccess, message] = await saveNewApprover(approver);
        }

        if (isSuccess) {
            toast.success("Saved New Approver Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            await fetchAllApprovers();
        }
        else {
            toast.error(`Error occurred:${message}`, { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoading(false);
        return [isSuccess, message]
    };

    const handleDeleteApprover = (approver: IApprover) => {
        setShowApproverDeleteModal(true);
        setCurrentApprover(approver);
        setRecordToDeleteMessage(`Are you sure to delete '${approver.FullName}' as a approver?`);
    };

    const confirmDelete = async () => {
        if (currentApprover != undefined && currentApprover?.Id && currentApprover?.Id > 0) {
            let [isSuccess, message] = await deleteApprover(currentApprover?.Id);

            if (isSuccess) {
                toast.success("Approver Deleted Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchAllApprovers();
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        }
        setCurrentApprover(null);
        setShowApproverDeleteModal(false);
    };

    const cancelDelete = () => {
        setCurrentApprover(null);
        setShowApproverDeleteModal(false);
    };

    return (
        <div>
            <div>
                <ConfirmationModal
                    show={showApproverDeleteModal}
                    title="Confirm Deletion of Approver"
                    message={recordToDeleteMessage}
                    onClose={() => setShowApproverDeleteModal(false)}
                    onCancel={cancelDelete}
                    onConfirm={confirmDelete}
                />
            </div>
            <div>
                {showModal && (
                    <ApproverCreate show={showModal}
                        onClose={toggleModal}
                        onSave={saveApprover}
                        initialApprover={currentApprover ?? initialDefaultApprover} />
                )}
            </div>
            <div className={loading ? 'card-body table-fade' : 'card-body'}>
                <div className="table-container" style={{ paddingRight: '20px', minHeight: '650px', maxHeight: '650px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #ccc' }}>
                    {loading ? (<LoadingIcon />) : ''}
                    <h2>All Approver</h2>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Approver</th>
                                    <th>Email</th>
                                    <th>BU Unit</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allApprovers?.map((approver) => (
                                    <tr key={approver.FullName}>
                                        <td>{approver.FullName}</td>
                                        <td>{approver.Email}</td>
                                        <td>{approver.BUUnit}</td>
                                        <td>{approver.IsActive ? "Active" : "In-Active"}</td>
                                        <td style={{ width: '55px' }}>
                                            <a onClick={() => editApprover(approver)} className="">
                                                <i className="fas fa-edit"></i>
                                            </a>
                                            <a style={{ marginLeft: '5px', color: 'red' }} onClick={() => handleDeleteApprover(approver)} className="">
                                                <i className="fas fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                {allApprovers?.length == 0 && (
                                    <tr>
                                        <td colSpan={5}>
                                            <div style={{ marginLeft: '40%', fontSize: '14pt', fontWeight: 'bold' }}>No approver found.</div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center', paddingTop: '10px', cursor: 'pointer' }}>
                {<a onClick={() => setShowModal(true)} className="w-100">
                    <i className="fas fa-plus"></i> Add New Approver
                </a>
                }
            </div>
        </div>
    )
};

export default ApproverSummary;