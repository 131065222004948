import React, { useEffect, useState } from 'react';
import { IRole, ISkillBase } from '../../data-model';
import { useDelayDebounce } from '../../common/hooks/debounce';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { useProjectDataContext } from '../../data-context/project-data-context';
import { Link, useParams } from 'react-router-dom';
import { attachSkillBaseToRoleGroup, detachSkillBaseFromRoleGroup, getAllSkills, getRoleDetailsById } from '../_services/pcr-rates-svc';
import { toast } from 'react-toastify';
import LoadingIcon from '../../common/loading-icon/loading-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const RoleSkillsMapping: React.FC<{}> = ({ }) => {
    const [selectedRole, setSelectedRole] = useState<IRole | null>();
    const [allDefaultSkillBase, setAllDefaultSkillBase] = useState<ISkillBase[]>([]);
    const [allAvailableSkillBase, setAllAvailableSkillBase] = useState<ISkillBase[]>([]);

    const [allFilterAvailableSkillBase, setAllFilterAvailableSkillBase] = useState<ISkillBase[]>([]);
    const [allFilterAttachedSkillBase, setAllFilterAttachedSkillBase] = useState<ISkillBase[]>([]);

    const [allSelectedSkills, setAllSelectedSkills] = useState<ISkillBase[]>([]);
    const [allDeSelectedSkills, setAllDeSelectedSkills] = useState<ISkillBase[]>([]);
    const [loadingSelectedSkills, setLoadingSelectedSkills] = useState(true);
    const [loadingDefaultSkills, setLoadingDefaultSkills] = useState(true);
    const [availableSkillFilter, setAvailableSkillFilter] = useState<string>('');
    const [attachedSkillFilter, setAttachedSkillFilter] = useState<string>('');
    const delayDebouncedSkills = useDelayDebounce(100);
    const delayDebouncedRole = useDelayDebounce(90);

    const { roleId } = useParams<{ roleId: string }>();
    let rightLastGroup = ''
    let leftLastGroup = ''

    let selectedRoleId: number;
    if (roleId != undefined)
        selectedRoleId = parseInt(roleId);
    else
        selectedRoleId = 0;

    useEffect(() => {
        if (selectedRoleId > 0 && delayDebouncedRole > 0) {
            fetchRoleDetails();
        }
    }, [delayDebouncedRole]);

    useEffect(() => {
        if (delayDebouncedSkills > 0 && allDefaultSkillBase.length == 0) {
            fetchAllDefaultSkills();
        }
    }, [delayDebouncedSkills]);

    useEffect(() => {
        if (availableSkillFilter?.length > 0) {
            const filteredData = allAvailableSkillBase?.filter((item) =>
                item.Skill?.toLowerCase().includes(availableSkillFilter?.toLowerCase())
                || item.SkillCategory?.toLowerCase().includes(availableSkillFilter?.toLowerCase())
            );
            setAllFilterAvailableSkillBase(filteredData);
        } else {
            setAllFilterAvailableSkillBase(allAvailableSkillBase);
        }
    }, [availableSkillFilter]);

    useEffect(() => {
        if (attachedSkillFilter?.length > 0) {
            const filteredData = selectedRole?.Skills?.filter((item) =>
                item.Skill?.toLowerCase().includes(attachedSkillFilter?.toLowerCase())
                || item.SkillCategory?.toLowerCase().includes(attachedSkillFilter?.toLowerCase())
            );
            setAllFilterAttachedSkillBase(filteredData ?? []);
        } else {
            setAllFilterAttachedSkillBase(selectedRole?.Skills ?? []);
        }
    }, [attachedSkillFilter]);

    useEffect(() => {
        if (allDefaultSkillBase?.length > 0 && selectedRole?.RoleId && selectedRole?.RoleId > 0) {
            let allRoleAvailableSkills = allDefaultSkillBase.filter(skill =>
                !selectedRole?.Skills?.some(selectedSkill => selectedSkill.RoleId === skill.RoleId && selectedSkill.Skill === skill.Skill
                    && selectedSkill.SkillCategory === skill.SkillCategory)
            );
            setAllAvailableSkillBase(allRoleAvailableSkills ?? []);
            setAllFilterAvailableSkillBase(allRoleAvailableSkills ?? [])
        } else {
            setAllAvailableSkillBase([]);
        }
    }, [allDefaultSkillBase, selectedRole?.Skills]);

    const fetchRoleDetails = async () => {
        if (selectedRoleId > 0) {
            setLoadingSelectedSkills(true);
            let data = await getRoleDetailsById(selectedRoleId);
            if (data) {
                setSelectedRole(data);
                setAllFilterAttachedSkillBase(data?.Skills ?? []);
            }
            setLoadingSelectedSkills(false);
        }
    }

    const fetchAllDefaultSkills = async () => {
        if (allDefaultSkillBase?.length == 0) {
            setLoadingDefaultSkills(true);
            let skills = await getAllSkills();
            if (skills) {
                setAllDefaultSkillBase(skills ?? []);
                setAllFilterAvailableSkillBase(skills ?? []);
            }
            setLoadingDefaultSkills(false);
        }
    }

    const handleAttach = async () => {
        if (allSelectedSkills.length > 0) {
            let [isSuccess, message] = await attachSkillBaseToRoleGroup(selectedRoleId, allSelectedSkills);

            if (isSuccess) {
                toast.success("Attached Skill Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchRoleDetails();
                setAllSelectedSkills([]);
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
            setLoadingSelectedSkills(false);
        }
    };

    const handleDetach = async () => {
        if (allDeSelectedSkills.length > 0) {
            let [isSuccess, message] = await detachSkillBaseFromRoleGroup(selectedRoleId, allDeSelectedSkills);

            if (isSuccess) {
                toast.success("Removed Skill Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchRoleDetails();
                setAllDeSelectedSkills([]);
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
            setLoadingSelectedSkills(false);
        }
    };

    const handleSelectedSkillChange = (event: React.ChangeEvent<HTMLInputElement>, skill: ISkillBase) => {
        const { name, value, checked } = event.target;
        if (!checked) {
            const isExist = allDeSelectedSkills.some((item) => item.SkillBaseId == skill.SkillBaseId);
            if (isExist) {
                setAllDeSelectedSkills(allDeSelectedSkills.filter((item) => item.SkillBaseId != skill.SkillBaseId));
            }
        }
        else {
            const isExist = allDeSelectedSkills.some((item) => item.SkillBaseId == skill.SkillBaseId);
            if (!isExist) {
                setAllDeSelectedSkills([...allDeSelectedSkills, skill]);
            }
        }
    };

    const handleDefaultSkillChange = (event: React.ChangeEvent<HTMLInputElement>, skill: ISkillBase) => {
        const { name, value, checked } = event.target;
        if (!checked) {
            const isExist = allSelectedSkills.some((item) => item.SkillBaseId == skill.SkillBaseId);
            if (isExist) {
                setAllSelectedSkills(allSelectedSkills.filter((item) => item.SkillBaseId != skill.SkillBaseId));
            }
        }
        else {
            const isExist = allSelectedSkills.some((item) => item.SkillBaseId == skill.SkillBaseId);
            if (!isExist) {
                setAllSelectedSkills([...allSelectedSkills, skill]);
            }
        }
    };

    return (
        <>
            <div>
                <center><h2>Role Group - Skill Mapping</h2></center>
                <div style={{ paddingBottom: '2px' }} className="d-flex justify-content-left">
                    <p style={{ fontWeight: 'bold', paddingRight: '10px' }}>Group:</p>
                    <Link to={`/role-details/${selectedRole?.RoleId}`}>
                        {selectedRole?.RoleGroup}
                    </Link>
                </div>
                <div className='row' style={{ border: '1px solid grey' }}>
                    <div className="col-xl-5 col-md-5 col-lg-5" style={{ borderRight: '2px solid grey' }}>
                        <div className="d-flex justify-content-between">
                            <h3 style={{ backgroundColor: '#a261f2' }}>Attached Skill(s):</h3>
                            <p className="pr-3">Display: <strong>{allFilterAttachedSkillBase?.length} </strong> / <strong> {selectedRole?.Skills?.length} </strong>  skill(s)</p>
                            <Form.Control
                                type="text"
                                name="filterSkill"
                                placeholder='Search Skill'
                                value={attachedSkillFilter}
                                style={{ width: '50%' }}
                                onChange={(e) => setAttachedSkillFilter(e.target.value)}
                            />
                        </div>

                        <div style={{ marginLeft: '20px', marginTop: '5px', overflowY: 'scroll', minHeight: '700px', maxHeight: '700px' }}>
                            {loadingSelectedSkills ? (<LoadingIcon />) : ''}
                            {allFilterAttachedSkillBase?.map((item) => {
                                let isRoleDeSelected: boolean = allDeSelectedSkills.some((s) => item.SkillBaseId == s.SkillBaseId);
                                let displaySubCategory = false;
                                if (item.SkillCategory != leftLastGroup) {
                                    displaySubCategory = true;
                                    leftLastGroup = item.SkillCategory ?? 'N/A';
                                }
                                return (<>
                                    {displaySubCategory &&
                                        <div className="d-flex"><FontAwesomeIcon icon={faChevronRight} /> <strong className='pl-2'>{`${item.SkillCategory ?? ''}`}</strong></div>}
                                    <div className='pl-4'>
                                        <Form.Check
                                            style={{ marginTop: '3px', fontSize: '15pt' }}
                                            key={`attachedskill-${item.SkillBaseId}`}
                                            disabled={false}
                                            type="checkbox"
                                            className="highlight-checkbox"
                                            name='attachedSkill'
                                            label={item.Skill}
                                            checked={isRoleDeSelected}
                                            onChange={(e) => handleSelectedSkillChange(e, item)}
                                        />
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                    <div className="col-xl-1 col-md-1 col-lg-1" style={{ marginTop: '180px' }}>
                        <Button disabled={allSelectedSkills.length == 0} variant="primary" style={{ width: '100px' }} onClick={handleAttach}>
                            {'<--'}
                        </Button>
                        <Button disabled={allDeSelectedSkills.length == 0} variant="primary" style={{ marginTop: '10px', width: '100px' }} onClick={handleDetach}>
                            {'-->'}
                        </Button>
                    </div>
                    <div className="col-xl-5 col-md-5 col-lg-5" style={{ borderLeft: '2px solid grey' }}>
                        <div className="d-flex justify-content-between">
                            <h3 style={{ backgroundColor: '#226af2' }}>Available Skills:</h3>
                            <p className="pr-3">Display: <strong>{allFilterAvailableSkillBase?.length} </strong> / <strong> {allAvailableSkillBase?.length} </strong>  skill(s)</p>

                            <Form.Control
                                type="text"
                                name="filterSkill"
                                placeholder='Search Skill'
                                value={availableSkillFilter}
                                style={{ width: '50%' }}
                                onChange={(e) => setAvailableSkillFilter(e.target.value)}
                            />
                        </div>
                        <div style={{ marginLeft: '20px', marginTop: '5px', overflowY: 'scroll', minHeight: '700px', maxHeight: '700px' }}>
                            {loadingSelectedSkills ? (<LoadingIcon />) : ''}
                            {allFilterAvailableSkillBase?.map((item) => {
                                let isRoleSelected: boolean = allSelectedSkills.some((s) => item.SkillBaseId == s.SkillBaseId);
                                let displaySubCategory = false;
                                if (item.SkillCategory != rightLastGroup) {
                                    displaySubCategory = true;
                                    rightLastGroup = item.SkillCategory ?? 'N/A';
                                }
                                return (<>
                                    {displaySubCategory &&
                                        <div className="d-flex"><FontAwesomeIcon icon={faChevronRight} />  <strong className='pl-2'>{`${item.SkillCategory ?? ''}`}</strong></div>}
                                    <div className='pl-4'>
                                        <Form.Check
                                            style={{ marginTop: '3px', fontSize: '15pt' }}
                                            key={`available${item.SkillBaseId}`}
                                            disabled={false}
                                            className="highlight-checkbox"
                                            type="checkbox"
                                            title={item.Description ?? ''}
                                            name='availableSkill'
                                            label={item.Skill}
                                            checked={isRoleSelected}
                                            onChange={(e) => handleDefaultSkillChange(e, item)}
                                        />
                                    </div>
                                </>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Link to={`/role-summary`}>
                        All Role(s)
                    </Link>
                </div>
            </div >
        </>
    );
};

export default RoleSkillsMapping;
