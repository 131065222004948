import { useEffect, useState } from "react"
import { getAllRoles, getPCRRateSummary, saveNewRole, updateRole, deleteRole } from "../_services/pcr-rates-svc";
import { IRole } from "../../data-model";
import { Link, useParams } from "react-router-dom";
import { Form, Table } from "react-bootstrap";
import ConfirmationModal from "../../common/confirmation-dialog";
import LoadingIcon from "../../common/loading-icon/loading-icon";
import RoleCreate from "./role-create";
import { toast } from "react-toastify";
import { useDebounce, useDelayDebounce } from "../../common/hooks/debounce";
import { initialDefaultRole } from "../_services/initial-default-data";
import { useProjectDataContext } from "../../data-context/project-data-context";
import { isUserPCRRoleReadOnly } from "../../utilities/helper-function";

const RoleSummary: React.FC<{}> = () => {
    const [showModal, setShowModal] = useState(false);
    const [allRoles, setAllRoles] = useState<IRole[]>();
    const [filteredRoles, setFilteredRoles] = useState<IRole[]>();
    const [fieldFilter, setFieldFilter] = useState<string>('');
    const [loading, setLoading] = useState(true);

    const [currentRole, setCurrentRole] = useState<IRole | null>();
    const [recordToDeleteMessage, setRecordToDeleteMessage] = useState<string>('');
    const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false);
    const [isPCRRoleAddEdit, setIsPCRRoleAddEdit] = useState<boolean>(false);
    const delayDebouncedValue = useDelayDebounce(100);

    const { userAccessInfoGblCtx } = useProjectDataContext();

    useEffect(() => {
        if (delayDebouncedValue) {
            setIsPCRRoleAddEdit(!isUserPCRRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles))
            fetchAllRoles();
        }
    }, [delayDebouncedValue]);

    useEffect(() => {
        if (fieldFilter?.length > 0) {
            const filteredData = allRoles?.filter((item) =>
                item.Name?.toLowerCase().includes(fieldFilter?.toLowerCase())
                || item.RoleGroup?.toLowerCase().includes(fieldFilter?.toLowerCase())
            );
            setFilteredRoles(filteredData);
        } else {
            setFilteredRoles(allRoles);
        }
    }, [fieldFilter]);


    const fetchAllRoles = async () => {
        setLoading(true);
        const data = await getAllRoles();
        if (data) {
            setAllRoles(data);
            setFilteredRoles(data)
        } else {
            setAllRoles([]);
        }
        setLoading(false);
    };

    const toggleModal = () => {
        setCurrentRole(null);
        setShowModal(!showModal);
    };

    const editRole = (role: IRole) => {
        setShowModal(!showModal);

        setCurrentRole({
            ...role
        });
    };

    const saveRole = async (role: IRole) => {
        let [isSuccess, message] = [false, ''];
        if (role.RoleId > 0) {
            [isSuccess, message] = await updateRole(role);
        }
        else {
            [isSuccess, message] = await saveNewRole(role);
        }

        if (isSuccess) {
            toast.success("Saved New Role Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
            await fetchAllRoles();
        }
        else {
            toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
        setLoading(false);
        return [isSuccess, message]
    };

    const handleDeleteRole = (role: IRole) => {
        setShowRoleDeleteModal(true);
        setCurrentRole(role);
        setRecordToDeleteMessage(`Are you sure to delete role: '${role.Name}'`);
    };

    const confirmDelete = async () => {
        if (currentRole != undefined && currentRole.RoleId > 0) {
            let [isSuccess, message] = await deleteRole(currentRole?.RoleId);

            if (isSuccess) {
                toast.success("Role Deleted Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
                await fetchAllRoles();
            }
            else {
                toast.error("Error occurred", { autoClose: 20000, position: toast.POSITION.TOP_CENTER });
            }
        }
        setCurrentRole(null);
        setShowRoleDeleteModal(false);
    };

    const cancelDelete = () => {
        setCurrentRole(null);
        setShowRoleDeleteModal(false);
    };

    return (
        <div>
            <div>
                <ConfirmationModal
                    show={showRoleDeleteModal}
                    title="Confirm Deletion of Role"
                    message={recordToDeleteMessage}
                    onClose={() => setShowRoleDeleteModal(false)}
                    onCancel={cancelDelete}
                    onConfirm={confirmDelete}
                />
            </div>
            <div>
                {showModal && (
                    <RoleCreate show={showModal} onClose={toggleModal} onSave={saveRole} initialRole={currentRole ?? initialDefaultRole} />
                )}
            </div>
            <center><h2>All Roles</h2></center>

            <div className={loading ? 'card-body table-fade' : 'card-body'}>
                <div className="table-container" style={{ minHeight: '750px', maxHeight: '750px', border: '1px solid #ccc' }}>
                    {loading ? (<LoadingIcon />) : ''}
                    <div className="d-flex justify-content-between">
                        <div>
                            {isPCRRoleAddEdit && <div style={{ textAlign: 'center', paddingTop: '10px', cursor: 'pointer' }}>
                                {<a onClick={() => setShowModal(true)} className="w-100">
                                    <i className="fas fa-plus"></i> Add New Role
                                </a>
                                }
                            </div>
                            }
                        </div>

                        <p className="pr-3">Display: <strong>{filteredRoles?.length} </strong> Role(s) out of Total:<strong> {allRoles?.length} </strong>  Roles</p>
                    </div>
                    <div>
                        <Table striped bordered hover>
                            <thead style={{ position: 'sticky', top: '0' }}>
                                <tr>
                                    <th className="w-25">
                                        <div className="d-flex justify-content-between">
                                            <p className="mt-0">Role</p>

                                            <Form.Control
                                                type="text"
                                                className="w-75 h-25"
                                                name="RoleName"
                                                placeholder='Filter Role'
                                                value={fieldFilter}
                                                onChange={(e) => setFieldFilter(e.target.value)}
                                            />
                                        </div>

                                    </th>
                                    <th>Code</th>
                                    <th style={{ width: '10%' }}>Group</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    {isPCRRoleAddEdit && <th>Actions</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRoles?.map((role) => (
                                    <tr key={role.Name}>
                                        <td style={{ width: '200px' }}>
                                            <Link to={`/role-details/${role.RoleId}`}>
                                                {role.Name}
                                            </Link>
                                        </td>
                                        <td>{role.Code}</td>
                                        <td>
                                            <Link to={`/role-skills-mapping/${role.RoleId}`}>
                                                {role.RoleGroup}
                                            </Link>
                                        </td>
                                        <td>{role.Description}</td>
                                        <td>{role.IsActive ? "Active" : "In-Active"}</td>
                                        {isPCRRoleAddEdit && <td style={{ width: '55px' }}>
                                            <a onClick={() => editRole(role)} className="">
                                                <i className="fas fa-edit"></i>
                                            </a>
                                            <a style={{ marginLeft: '5px', color: 'red' }} onClick={() => handleDeleteRole(role)} className="">
                                                <i className="fas fa-trash"></i>
                                            </a>
                                        </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            {isPCRRoleAddEdit && <div style={{ textAlign: 'center', paddingTop: '10px', cursor: 'pointer' }}>
                {<a onClick={() => setShowModal(true)} className="w-100">
                    <i className="fas fa-plus"></i> Add New Role
                </a>
                }
            </div>
            }
        </div>
    )
};

export default RoleSummary;
