import { deleteApiData, getApiData, postApiData } from '../../app-services'
import { IEmployee, HubspotCustomer, IProjectTemplate, IProjectStatus, IInvoiceFrequency } from '../../data-model';
import { HubspotCompanyDealProperty } from '../../data-model/hubspot-customer';
import ISkillCategory from '../../data-model/skill-category';
import { parseAndCastDate } from '../../utilities/helper-function';
import { getBaseFTTAPIURLS } from './common-svc';

const getAllMatchedHubspotCustomer = async (searchText: string): Promise<HubspotCustomer[]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let allMatchedCustomer: HubspotCustomer[] = [];
    try {
        let url = `${baseAPIUrls}/masterdata/hubspot/clients?searchText=${searchText}`;
        var apiResponse = await getApiData(url);

        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            allMatchedCustomer = mapCustomerResponse(apiResponse.Result);
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return allMatchedCustomer;
}

const getAllMatchedHubspotDeal = async (searchText: string): Promise<HubspotCustomer[]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let allMatchedDeal: HubspotCustomer[] = [];
    try {
        let url = `${baseAPIUrls}/masterdata/hubspot/deal?searchText=${searchText}`;
        var apiResponse = await getApiData(url);

        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            allMatchedDeal = mapCustomerResponse(apiResponse.Result);
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return allMatchedDeal;
}

function mapCustomerResponse(apiResponse: any): HubspotCustomer[] {
    return apiResponse.map((result: any) => ({
        ...result
    }));
}

const getAllMatchedHubspotCustomerDeals = async (clientId: string): Promise<HubspotCompanyDealProperty[]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let allMatchedCustomer: HubspotCompanyDealProperty[] = [];
    try {
        let url = `${baseAPIUrls}/masterdata/hubspot/client/deals/${clientId}`;
        var apiResponse = await getApiData(url);

        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            allMatchedCustomer = mapCustomerDealResponse(apiResponse.Result);
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return allMatchedCustomer;
}

function mapCustomerDealResponse(apiResponse: any): HubspotCompanyDealProperty[] {
    return apiResponse.map((result: any) => ({
        ...result,
        StartDate: result.StartDate != null ? parseAndCastDate(result.StartDate) : null
    }));
}

const getLatestHubspotCustomerDeals = async (clientId: number, dealId: number): Promise<HubspotCompanyDealProperty | null> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let deal: HubspotCompanyDealProperty | null = null;
    try {
        let url = `${baseAPIUrls}/masterdata/hubspot/client/deal/${clientId}/${dealId}`;
        var apiResponse = await getApiData(url);

        if (apiResponse?.Success && apiResponse.Result) {
            deal = {
                ...apiResponse.Result,
                StartDate: apiResponse.Result?.StartDate != null ? parseAndCastDate(apiResponse.Result?.StartDate) : null
            }
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return deal;
}

const getAllCWMemberData = async (searchText: string): Promise<IEmployee[]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let allMatchedCustomer: IEmployee[] = [];
    try {
        let url = `${baseAPIUrls}/masterdata/cw/member?searchText=${searchText}`;
        var apiResponse = await getApiData(url);

        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            allMatchedCustomer = mapEmployeeResponse(apiResponse.Result);
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return allMatchedCustomer;
}

function mapEmployeeResponse(apiResponse: any): IEmployee[] {
    return apiResponse.map((result: any) => ({
        ...result,
        fullName: result.firstName + " " + result.lastName
    }));
}

const getAllMasterProjectStatus = async (): Promise<IProjectStatus[]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let allProjectStatus: IProjectStatus[] = [];
    try {
        let url = `${baseAPIUrls}/masterdata/project/status`;
        var apiResponse = await getApiData(url);
        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            allProjectStatus = mapProjectStatusResponse(apiResponse.Result);
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return allProjectStatus;
}

function mapProjectStatusResponse(apiResponse: any): IProjectStatus[] {
    return apiResponse.map((result: any) => ({
        ...result
    }));
}


const getAllMasterInvoiceFrequency = async (): Promise<IInvoiceFrequency[]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let allInvoiceFrequency: IInvoiceFrequency[] = [];
    try {
        let url = `${baseAPIUrls}/masterdata/invoice/frequencies`;
        var apiResponse = await getApiData(url);
        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            allInvoiceFrequency = apiResponse.Result.map((result: any) => ({
                ...result
            }));
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return allInvoiceFrequency;
}

const fetchAllMatchedProjectTemplateData = async (searchText: string): Promise<IProjectTemplate[]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let allMatchedProjectTemplate: IProjectTemplate[] = [];
    try {
        let url = `${baseAPIUrls}/masterdata/project/template?searchText=${searchText}`;
        var apiResponse = await getApiData(url);

        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            allMatchedProjectTemplate = mapProjectTemplate(apiResponse.Result);
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return allMatchedProjectTemplate;
}

function mapProjectTemplate(apiResponse: any): IProjectTemplate[] {
    return apiResponse.map((result: any) => ({
        ...result,
        DisplayText: `${result.Title} | ${result.VersionName ?? ''} | ${result.Status}`
    }));
}

const clearCache = async (cacheKey: string): Promise<[boolean]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/masterdata/cache/clear?cacheKey=${cacheKey}`;
    var apiResponse = await deleteApiData(url);
    return [apiResponse?.Success];
}

const createAllApplicationMasterData = async (): Promise<[boolean]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let url = `${baseAPIUrls}/masterdata/create/all`;
    var apiResponse = await postApiData(url);
    return [apiResponse?.Success];
}

const getAllMatchedSkillCategory = async (searchText: string): Promise<ISkillCategory[]> => {
    const baseAPIUrls = getBaseFTTAPIURLS();;
    let skillBaseCategories: ISkillCategory[] = [];
    try {
        let url = `${baseAPIUrls}/masterdata/skill/matched/category?searchText=${searchText}`;
        var apiResponse = await getApiData(url);

        if (apiResponse?.Success && apiResponse.Result?.length > 0) {
            skillBaseCategories = apiResponse.Result.map((result: any) => ({
                ...result
            }));
        }
    } catch (e) {
        console.error('Unable to connect API server: ', baseAPIUrls);
    }
    return skillBaseCategories;
}

export {
    getAllMatchedHubspotCustomer, getAllCWMemberData, getAllMatchedHubspotCustomerDeals,
    fetchAllMatchedProjectTemplateData, getAllMasterProjectStatus, getAllMasterInvoiceFrequency,
    getLatestHubspotCustomerDeals, getAllMatchedHubspotDeal, getAllMatchedSkillCategory,
    createAllApplicationMasterData, clearCache
};