import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements, useLocation } from "react-router-dom";
import ProjectDashboard from "../scenes/project-manage-home/project-dashboard";
import PCRRatesDetails from "../scenes/pcr-rates-manage/pcr-rates-details";
import PCRRatesManage from "../scenes/pcr-rates-manage";
import ProjectSummary from "../scenes/project-summary";
import ConstructionBanner from "../scenes/landing-page/construction-banner";
import ProjectReport from "../scenes/project-manage-home/project-report";
import RefreshCache from "../scenes/settings-manage/refresh-cache";
import RoleSummary from "../scenes/role-manage/role-summary";
import ManageCache from "../scenes/manage-permission/manage-cache";
import SkillBaseSummary from "../scenes/skill-base/skill-base-summary";
import RoleDetails from "../scenes/role-manage/role-details";
import SkillDetails from "../scenes/skill-base/skill-details";
import InvoiceScheduleManage from "../scenes/invoice-schedule";
import ManageMasterData from "../scenes/manage-permission/manage-master-data";
import ImportPCRRateFromPreviousYear from "../scenes/pcr-rates-manage/import-pcrrate-modal";
import ProtectedRoute from "../scenes/login/protected-route";
import AccessDeniedComponent from "../scenes/login/access-denied";
import ExcelGridPOC from "../scenes/project-effort-estimate-manage/excel-grid-poc-temp";
import ProjectLandingLayout from "../scenes/landing-page/project-layout-shared";
import ApproverSummary from "../scenes/approval-process/approval-summary";
import RoleSkillsMapping from "../scenes/role-manage/role-skills-mapping";

const APPRoute: React.FC<{}> = ({ }) => {

    let router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<ProjectLandingLayout />}>
                <Route path="/login-access-denied" element={<AccessDeniedComponent />} />
                <Route index element={<ProjectSummary />} />
                {/* <Route path="/" element={<ProjectSummary />} /> */}
                <Route path="/home" element={<ProjectSummary />} />
                <Route path="/project-dashboard/:id" element={<ProjectDashboard />} />
                <Route path="/project-template-new" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER", "FTT_ADMIN_USER"]}>
                        <ProjectDashboard />
                    </ProtectedRoute>
                } />
                <Route path="/import-pcr-rates" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER"]}>
                        <ImportPCRRateFromPreviousYear />
                    </ProtectedRoute>
                } />
                <Route path="/pcr-rates-manage" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER"]}>
                        <PCRRatesManage />
                    </ProtectedRoute>
                } />
                <Route path="/pcr-rates-detail/:PCRRateId" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER"]}>
                        <PCRRatesDetails />
                    </ProtectedRoute>
                } />
                <Route path="/page-in-construction" element={<ConstructionBanner />} />
                <Route path="/project-summary" element={<ProjectReport />} />
                <Route path="/refresh-cache" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER"]}>
                        <RefreshCache />
                    </ProtectedRoute>
                } />
                <Route path="/clear-cache" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER"]}>
                        <ManageCache />
                    </ProtectedRoute>
                } />
                <Route path="/create-master-data" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER"]}>
                        <ManageMasterData />
                    </ProtectedRoute>
                } />
                <Route path="/role-summary" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER", "FTT_RMO_Admin"]}>
                        <RoleSummary />
                    </ProtectedRoute>
                } />
                <Route path="/approver-summary" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER"]}>
                        <ApproverSummary />
                    </ProtectedRoute>
                } />

                <Route path="/skill-base-summary" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER", "FTT_RMO_Admin"]}>
                        <SkillBaseSummary />
                    </ProtectedRoute>
                } />
                <Route path="/skill-details/:skillId" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER", "FTT_RMO_Admin"]}>
                        <SkillDetails />
                    </ProtectedRoute>
                } />
                <Route path="/role-details/:roleId" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER", "FTT_RMO_Admin"]}>
                        <RoleDetails />
                    </ProtectedRoute>
                } />
                <Route path="/role-skills-mapping/:roleId" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER", "FTT_RMO_Admin"]}>
                        <RoleSkillsMapping />
                    </ProtectedRoute>
                } />
                <Route path="/invoice-schedule/:projectId" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER", "FTT_ADMIN_USER"]}>
                        <InvoiceScheduleManage />
                    </ProtectedRoute>
                } />

                <Route path="/poc" element={
                    <ProtectedRoute roles={["FTT_SUPER_ADMIN_USER", "FTT_ADMIN_USER"]}>
                        <ExcelGridPOC />
                    </ProtectedRoute>
                } />
                
            </Route>
        )
    );

    return <RouterProvider router={router} />;
}

export default APPRoute;