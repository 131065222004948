import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { IEmployee, HubspotCustomer, IProjectHeaderDetails } from '../../data-model';
import { toast } from 'react-toastify';
import { addCommasToNumber, areObjectsEqual, formatToUSCurrency, getMinDateAsString, parseDate, parseDateAsStringFormat } from '../../utilities/helper-function';
import { useProjectDataContext } from '../../data-context/project-data-context';
import AutocompleteSearch from '../../common/auto-complete/auto-complete-search';
import { getAllCWMemberData, getAllMatchedHubspotCustomer, getAllMatchedHubspotCustomerDeals, getLatestHubspotCustomerDeals } from '../_services/master-data-svc';
import { HubspotCompanyDealProperty } from '../../data-model/hubspot-customer';
import LoadingIcon from '../../common/loading-icon/loading-icon';
import { defaultProjectHeaderDetails } from '../_services/initial-default-data';

const ProjectHeaderCreate: React.FC<{

  show: boolean;
  onClose: () => void;
  onSave: (updatedDetails: IProjectHeaderDetails) => void;
  projectDetails: IProjectHeaderDetails;
}> = ({ show, onClose, projectDetails, onSave }) => {

  const [currentProjectHeader, setCurrentProjectHeader] = useState<IProjectHeaderDetails>(projectDetails);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [saveHeaderDisabled, setSaveHeaderDisabled] = useState<boolean>(true);
  const { currentProjectIdGblCtx, currentProjectInfoGblCtx, refreshProjectHeaderGblCtx } = useProjectDataContext();
  const [selectedClientDeals, setSelectedClientDeals] = useState<HubspotCompanyDealProperty[]>();
  const [loadingDeals, setLoadingDeals] = useState<boolean>(false);
  const [disabledSyncHubspotDealLink, setDisabledSyncHubspotDealLink] = useState<boolean>(true);
  const [loadingDealUpdate, setLoadingDealUpdate] = useState<boolean>(false);
  const [searchDealText, setSearchDealText] = useState<string | null>();

  let projectId = projectDetails.ProjectId != undefined && projectDetails.ProjectId > 0 ? projectDetails.ProjectId : currentProjectIdGblCtx;
  const actionText = projectId != undefined && projectId > 0 ? "Edit" : "Add";
  const actionButtonText = projectId != undefined && projectId > 0 ? "Update" : "Save";

  useEffect(() => {
    setCurrentProjectHeader(projectDetails);
    if (projectDetails && projectDetails.HubspotClientId && projectDetails.HubspotDealId && projectDetails.HubspotClientId > 0 && projectDetails.HubspotDealId > 0) {
      setDisabledSyncHubspotDealLink(false);
    } else {
      setDisabledSyncHubspotDealLink(true);
    }
  }, [projectDetails]);

  useEffect(() => {
    if (refreshProjectHeaderGblCtx && refreshProjectHeaderGblCtx > 0) {
      setCurrentProjectHeader(currentProjectInfoGblCtx ?? defaultProjectHeaderDetails);
    }
  }, [refreshProjectHeaderGblCtx]);

  useEffect(() => {
    if (currentProjectHeader?.HubspotClientId && currentProjectHeader?.HubspotClientId > 0) {
      fetchHubspotDealsFromHSClient(currentProjectHeader.HubspotClientId)?.then((deals) => {
        if (searchDealText && searchDealText?.length > 10 && searchDealText?.length < 13) {
          let matchedDeal = deals.filter(deal => deal.DealId?.toString() == searchDealText?.trim())[0];
          if (matchedDeal && matchedDeal.DealId && parseInt(matchedDeal.DealId) > 0) {
            setCurrentProjectHeader({
              ...currentProjectHeader,
              HubspotDealId: parseInt(matchedDeal.DealId),
              HubspotDealName: matchedDeal.DealName,
              HubspotDealBUName: matchedDeal.HubspotDealBUName,
              ClientName: currentProjectHeader.ClientName,
              HubspotClientId: currentProjectHeader.HubspotClientId
            });
          }
        }
      });
    } else { //no hs client reset deal
      setCurrentProjectHeader((prevData) => ({
        ...prevData,
        HubspotDealId: null,
        HubspotDealName: null,
        HubspotDealBUName: null
      }));
    }
  }, [currentProjectHeader?.HubspotClientId]);

  useEffect(() => {
    setSaveHeaderDisabled(areObjectsEqual(projectDetails, currentProjectHeader))
  }, [currentProjectHeader]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'DatePrepared' || name === 'StartDate' || name === 'EndDate') {
      try {
        setCurrentProjectHeader((prevData) => ({
          ...prevData,
          [name]: value ? new Date(value) : null,
        }));
      } catch (error) {
        console.error('error', error);
      }
    } else if (name === 'OfferedPrice') {
      const numericValue = value?.replace(/[^\d.]/g, '');
      setCurrentProjectHeader((prevData) => ({
        ...prevData,
        [name]: parseFloat(numericValue),
      }));
    } else {
      setCurrentProjectHeader((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (name === 'hubspotDeal') {
      handleDealSelectedChange(event);
    }

    validateFormData(name, value);
  };

  const handleDealSelectedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedRole = event.target.value;
    const selectedDealObj = selectedClientDeals?.find((deal) => deal.DealId == selectedRole);

    if (selectedDealObj) {
      setCurrentProjectHeader({
        ...currentProjectHeader,
        HubspotDealId: selectedDealObj.DealId ? parseInt(selectedDealObj.DealId) : null,
        HubspotDealName: selectedDealObj.DealName,
        HubspotDealBUName: selectedDealObj.HubspotDealBUName,
        ExecutiveSponsor: selectedDealObj.ExecutiveSponsorName ?? '',
        EngagementManager: selectedDealObj.EngagementManager ?? '',
        StartDate: selectedDealObj.StartDate ?? null
      });
    } else {
      setCurrentProjectHeader({
        ...currentProjectHeader,
        HubspotDealId: null,
        HubspotDealName: null,
        HubspotDealBUName: null
      });
    }
  };

  const validateFormData = (name: string, value: string) => {
    const errors: { [key: string]: string } = {};
    if (!currentProjectHeader.Title) {
      errors.Title = 'Title is required';
    }
    if (!currentProjectHeader?.StartDate) {
      errors.StartDate = 'Start Date is required';
    }
    if (!currentProjectHeader.ClientName) {
      errors.ClientName = 'ClientName is required';
    }
    if (!currentProjectHeader.ExecutiveSponsor) {
      errors.ExecutiveSponsor = 'Executive Sponsor is required';
    }
    if (!currentProjectHeader.EngagementManager) {
      errors.EngagementManager = 'Engagement Manager is required';
    }
    if (!currentProjectHeader.OfferedPrice) {
      errors.OfferedPrice = 'Offered Price is required';
    } else {
      if (currentProjectHeader?.EndDate && currentProjectHeader?.EndDate > new Date('01/01/2050')) {
        errors.EndDate = 'Invalid End Date';
      }
      if (!(currentProjectHeader.OfferedPrice >= 0 && currentProjectHeader.OfferedPrice <= 999999999)) {
        errors.OfferedPrice = 'Invalid Offered Price. Value between 0 and 1,000,000,000';
      }
      if (currentProjectHeader?.EndDate && currentProjectHeader?.StartDate && currentProjectHeader?.StartDate > currentProjectHeader?.EndDate) {
        errors.EndDate = 'End date should be greater then start date';
      }
    }

    // Clear validation error when the input becomes valid
    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }

    return errors;
  }

  const handleSave = async () => {
    const errors = validateFormData("", "");

    if (Object.keys(errors).length === 0) {
      try {
        onSave(currentProjectHeader);
        onClose();
      } catch (error) {
        console.error('API call failed:', error);
        // Handle API error here
        return toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
      }
    } else {
      // Validation errors exist, update the state with error messages
      setValidationErrors(errors);
    }
  };

  const resetForm = () => {
    setCurrentProjectHeader(projectDetails);
    fetchHubspotDealsFromHSClient(projectDetails.HubspotClientId ?? 0)
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleUpdateHubspotDealLatestData = async () => {
    if (projectDetails?.HubspotClientId && projectDetails?.HubspotClientId > 0 && projectDetails?.HubspotDealId && projectDetails?.HubspotDealId > 0) {
      setLoadingDealUpdate(true);
      setDisabledSyncHubspotDealLink(true);
      try {
        let deal = await getLatestHubspotCustomerDeals(projectDetails.HubspotClientId, projectDetails.HubspotDealId);
        if (deal) {
          if (deal.EngagementManager && deal.EngagementManager != currentProjectHeader.EngagementManager) {
            setCurrentProjectHeader((prevData) => ({
              ...prevData,
              "EngagementManager": deal?.EngagementManager ?? '',
            }));
          }
          if (deal.StartDate && deal.StartDate != currentProjectHeader.StartDate) {
            setCurrentProjectHeader((prevData) => ({
              ...prevData,
              "StartDate": deal?.StartDate ?? null,
            }));
          }
          if (deal.ExecutiveSponsorName && deal.ExecutiveSponsorName != currentProjectHeader.ExecutiveSponsor) {
            setCurrentProjectHeader((prevData) => ({
              ...prevData,
              "ExecutiveSponsor": deal?.ExecutiveSponsorName ?? '',
            }));
          }
          if (deal.HubspotDealBUName && deal.HubspotDealBUName != currentProjectHeader.HubspotDealBUName) {
            setCurrentProjectHeader((prevData) => ({
              ...prevData,
              "HubspotDealBUName": deal?.HubspotDealBUName ?? '',
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching deal details:', error);
      }
      setLoadingDealUpdate(false);
      setDisabledSyncHubspotDealLink(false);
    }
  };

  const fetchHubspotDealsFromHSClient = (hsClientId: number, searchDealIdText?: string | null): Promise<HubspotCompanyDealProperty[]> | null => {
    if (hsClientId > 0) {
      setSelectedClientDeals([]);
      setLoadingDeals(true);
      let deals = getAllMatchedHubspotCustomerDeals(hsClientId.toString()).then(resDeals => {
        if (resDeals && resDeals.length > 0) {
          setSelectedClientDeals(resDeals);
        } else {
          setCurrentProjectHeader({
            ...currentProjectHeader,
            HubspotDealId: null,
            HubspotDealName: null,
            HubspotDealBUName: null
          });
        }
        setLoadingDeals(false);
        return resDeals;
      });
      return deals;
    }
    return null;
  }

  const fetchHubspotClientFromSearchText = (searchText: string): Promise<HubspotCustomer[]> => {
    return getAllMatchedHubspotCustomer(searchText).then((res) => {
      if (!res || res?.length == 0) {
        setSelectedClientDeals([]);
      } else {
        setSearchDealText(searchText)
      }
      return res;
    });
  }

  const handleClientNameSelected = (item: HubspotCustomer | undefined, selectedText: string) => {
    let hsClientId = (item != undefined ? parseInt(item.value ?? '0') : null);

    setCurrentProjectHeader((prevData) => ({
      ...prevData,
      ClientName: (item != undefined ? item.name : selectedText) ?? '',
      HubspotClientId: hsClientId
    }));
    if (hsClientId && hsClientId > 0) {
      fetchHubspotDealsFromHSClient(hsClientId, searchDealText)
    }

    validateFormData("ClientName", (item != undefined ? item.name : selectedText) ?? '');
  };

  const handleExecutiveSponsorSelected = (item: IEmployee | undefined, selectedText: string) => {
    setCurrentProjectHeader((prevData) => ({
      ...prevData,
      ["ExecutiveSponsor"]: (item != undefined ? item.fullName : selectedText) ?? '',
    }));

    validateFormData("ExecutiveSponsor", (item != undefined ? item.fullName : selectedText) ?? '');
  };

  const handleEngagementManagerSelected = (item: IEmployee | undefined, selectedText: string) => {
    setCurrentProjectHeader((prevData) => ({
      ...prevData,
      ["EngagementManager"]: (item != undefined ? item.fullName : selectedText) ?? '',
    }));
    validateFormData("EngagementManager", (item != undefined ? item.fullName : selectedText) ?? '');
  };

  return (
    <div id="createForm" className='createForm'>
      <Modal onClick={handleModalClick} show={show} onHide={onClose} onClose={onClose} centered backdrop="static" dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>{actionText} Project Header</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="formProject">
                  <Form.Label>Title<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="Title"
                    placeholder={'Type Title..'}
                    value={currentProjectHeader.Title}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.Title}
                  />
                  {
                    validationErrors.Title && <p style={{ color: 'red' }} className="error-message">{validationErrors.Title}*</p>
                  }
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formClientName">
                  <Form.Label>Client Name<span style={{ color: 'red' }}>*</span></Form.Label>
                  <AutocompleteSearch
                    OnItemSelected={handleClientNameSelected}
                    SelectedInitialItem={currentProjectHeader.ClientName}
                    PlaceHolder='Type Customer Name..'
                    APICallback={(searchText) => fetchHubspotClientFromSearchText(searchText)}
                    AllowFreeText={true}
                    ShowVaidationError={!!validationErrors.ClientName}
                    DisplayProperty={(item) => item.name ?? 'name'}
                  />
                  {
                    validationErrors.ClientName && <p style={{ color: 'red' }} className="error-message">{validationErrors.ClientName}*</p>
                  }
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="dealId">
                  <Form.Label>Select Hubspot Deal</Form.Label>
                  {loadingDeals ? <LoadingIcon /> : <Form.Control as="select"
                    name="hubspotDeal"
                    value={currentProjectHeader?.HubspotDealId ?? -1}
                    title={currentProjectHeader.HubspotDealName ?? ''}
                    onChange={handleInputChange}>
                    <option value='-1'>Select a deal</option>
                    {!(selectedClientDeals && selectedClientDeals?.length > 0) && (currentProjectHeader?.HubspotDealId && currentProjectHeader?.HubspotDealId > 0)
                      && <option value={currentProjectHeader?.HubspotDealId ?? -1}>{(currentProjectHeader.HubspotDealName && currentProjectHeader.HubspotDealName?.length > 0) ? currentProjectHeader.HubspotDealName : 'Select a deal'}</option>}
                    {
                      selectedClientDeals?.map((deal, index) => (
                        <option key={index} value={deal.DealId ?? -1}>
                          {`${deal.DealName} ${deal.DealStatus != null ? '| (' + deal.DealStatus + ')' : ''}`}
                        </option>
                      ))
                    }
                  </Form.Control>
                  }
                  {
                    validationErrors.Role && <p style={{ color: 'red' }} className="error-message">{validationErrors.Role}*</p>
                  }
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formStartDate">
                  <Form.Label>Start Date<span style={{ color: 'red' }}>*</span></Form.Label>
                  <a style={{ marginLeft: '5px', color: disabledSyncHubspotDealLink ? 'gray' : '', cursor: disabledSyncHubspotDealLink ? 'not-allowed' : 'pointer' }} href='#' onClick={handleUpdateHubspotDealLatestData}>
                    <i className="fas fa-sync" style={{ marginRight: '1px' }}></i> update
                  </a>
                  {loadingDealUpdate && <LoadingIcon />}
                  <Form.Control
                    type="date" // Use 'date' input type for date selection
                    name="StartDate"
                    //min={getMinDateAsString(new Date())}
                    value={parseDateAsStringFormat(currentProjectHeader?.StartDate)} // Format date as 'YYYY-MM-DD'
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.StartDate}
                  />
                  {
                    validationErrors.StartDate && <p style={{ color: 'red' }} className="error-message">{validationErrors.StartDate}*</p>
                  }
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="formEngagementManager">
                  <Form.Label>Engagement Manager<span style={{ color: 'red' }}>*</span></Form.Label>
                  <AutocompleteSearch
                    OnItemSelected={handleEngagementManagerSelected}
                    SelectedInitialItem={currentProjectHeader.EngagementManager}
                    PlaceHolder='Type Engagement Manager Name..'
                    APICallback={(searchText) => getAllCWMemberData(searchText)}
                    AllowFreeText={true}
                    ShowVaidationError={!!validationErrors.EngagementManager}
                    DisplayProperty={(item) => item.fullName ?? 'fullName'}
                  />
                  {
                    validationErrors.EngagementManager && <p style={{ color: 'red' }} className="error-message">{validationErrors.EngagementManager}*</p>
                  }
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formExecutiveSponsor">
                  <Form.Label>Executive Sponsor<span style={{ color: 'red' }}>*</span></Form.Label>
                  <AutocompleteSearch
                    OnItemSelected={handleExecutiveSponsorSelected}
                    SelectedInitialItem={currentProjectHeader.ExecutiveSponsor}
                    PlaceHolder='Type Sponsor Name..'
                    APICallback={(searchText) => getAllCWMemberData(searchText)}
                    AllowFreeText={true}
                    ShowVaidationError={!!validationErrors.ExecutiveSponsor}
                    DisplayProperty={(item) => item.fullName ?? 'fullName'}
                  />
                  {
                    validationErrors.ExecutiveSponsor && <p style={{ color: 'red' }} className="error-message">{validationErrors.ExecutiveSponsor}*</p>
                  }
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="formOfferedPrice">
                  <Form.Label>Offered Price($)<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="OfferedPrice"
                    placeholder='Type Offered Price'
                    autoComplete='off'
                    value={currentProjectHeader.OfferedPrice ? '$' + addCommasToNumber(currentProjectHeader.OfferedPrice.toString()) : ''}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.OfferedPrice}
                  />
                  {
                    validationErrors.OfferedPrice && <p style={{ color: 'red' }} className="error-message">{validationErrors.OfferedPrice}*</p>
                  }
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formStartDate">
                  <Form.Label>End Date(Approx.)</Form.Label>
                  <Form.Control
                    type="date" // Use 'date' input type for date selection
                    name="EndDate"
                    min={getMinDateAsString(currentProjectHeader?.StartDate)}
                    value={parseDateAsStringFormat(currentProjectHeader?.EndDate)} // Format date as 'YYYY-MM-DD'
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.EndDate}
                  />
                  {
                    validationErrors.EndDate && <p style={{ color: 'red' }} className="error-message">{validationErrors.EndDate}*</p>
                  }
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ marginTop: '15px' }} variant="secondary" onClick={resetForm}>
            <i className="fas fa-undo"></i> Reset
          </Button>
          <Button style={{ marginLeft: '10px', marginTop: '15px' }} variant="secondary" onClick={onClose}>
            <i className="fas fa-times"></i> Close
          </Button>
          {<Button style={{ marginLeft: '10px', marginRight: '10px', marginTop: '15px' }} disabled={saveHeaderDisabled} variant="primary" onClick={handleSave}>
            <i className="fas fa-save"></i> {actionButtonText}
          </Button>}

        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProjectHeaderCreate;
